import React from 'react'
import { useTranslation } from 'react-i18next'
import { UsersSearch } from './UserSearch'
import { UsersExport } from './UserExport'
import { Divider } from '../../common/Divider'

export function Users() {
    const { t } = useTranslation()

    return (
        <>
            <h2 className="title-page">{t('adminCompaniesUsers')}</h2>
            <div className="page-container">
                <UsersExport />
                <Divider border={false} size="lg" />
                <UsersSearch />
            </div>
        </>
    )
}