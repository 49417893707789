import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import countryRegionData from 'country-region-data/dist/data-umd'
// @ts-ignore
import italia from '@ksenia/italia'
import { useDispatch } from 'react-redux'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Company, CompanyStatus, CompanyType, TaxExempt } from '../selectors'
import { Button } from '../../common/Button'
import Input from '../../common/Input/Input'
import Select from '../../common/Select'
import Card from '../../common/Card/Card'
import { useCompanyMutation } from '../../hooks/createCompanyWizard'
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { reloadProfile } from '../actions'
import { belongsToEurope, isItalian } from '../../billing/utils'
import { PhoneWithCountry } from '../../common/PhoneWithCountry'
import Radio from '../../common/Radio'
import clsx from 'clsx'
import { useAdminCompanyMutation } from '../../hooks/adminCompanies'
import FormSelect from '../../common/FormSelect'
import { testEmail } from '../../common/utils'
import { useDebounce } from '../../hooks/utils'
import DistributorChoice from '../../dashboard/createCompany/DistributorChoice'
import { availableCountries, CountryRegion } from '../../dashboard/createCompany/utils'
import { PecCuu } from './PecCuu'
import { AdminNotes } from './AdminNotes'
import { useDistributors } from '../../hooks/createCompanyWizard/useDistributors'
import { Checkbox } from '../../common'
import { PromoCode } from '../../dashboard/createCompany/PromoCode'

interface Props extends Company {
    onClose?: () => void
    companyAdmin?: boolean,
    canAdmin?: boolean
}

type FormData = {
    address: string
    businessName: string
    city: string
    email: string
    country: string
    province: string
    zipCode: string
    phoneNumber?: string
    vatNumber?: string
    fiscalCode?: string
    pec?: string
    cuu?: string
    type?: string
    distributor?: any,
    distributorsNote?: string,
    businessProvinces?: any,
    note?: string,
    taxExempt?: TaxExempt,
    status: CompanyStatus,
    ignoreLoyaltyScore?: boolean
    promoCode?: string
}

const companyTypeOptions = (type: CompanyType) => {
    let options = [
        { id: 'installer', label: 'registrationInstaller', default: true },
        { id: 'distributor', label: 'registrationDistributor' }
    ]

    if (type === 'freelance')
        options.push({ id: 'freelance', label: 'registrationFreelance' })

    return options
}

export const mapDistributorOptions = (d: Pick<Company, '_id' | 'businessName'>) => ({
    label: d.businessName,
    value: d._id
})

export function CompanyProfile(props: Props) {
    const { companyAdmin, onClose, canAdmin = false } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [selectedCountry, setSelectedCountry] = useState(props.country)
    const [businessName, setBusinessName] = useState('')
    const { update } = useCompanyMutation()
    const adminHook = useAdminCompanyMutation()
    const { loading, execute } = useLoading()
    const debouncedBusinessName = useDebounce(businessName)

    const currentBusinessProvincesForForm = props.businessProvinces ? props.businessProvinces.map((v: any) => ({
        value: v.province,
        label: v.province
    })) : []

    const noneDistributor = { label: t('noneAbove'), value: 'none' }

    const defaultValues: FormData = {
        businessName: props.businessName,
        businessProvinces: currentBusinessProvincesForForm,
        address: props.address,
        city: props.city,
        zipCode: props.zipCode,
        province: props.province,
        country: props.country,
        email: props.email,
        vatNumber: props.vatNumber,
        pec: props.pec,
        cuu: props.cuu,
        phoneNumber: props.phoneNumber,
        type: props.type,
        note: props.note,
        status: props.status,
        distributorsNote: props.distributorsNote,
        distributor: props.distributors?.length ? props.distributors.map(mapDistributorOptions)[0] : undefined,
        fiscalCode: props.fiscalCode,
        taxExempt: props.taxExempt,
        ignoreLoyaltyScore: !!props.ignoreLoyaltyScore,
        promoCode: props.promoCode
    }
    const { register, handleSubmit, formState, control, watch, getValues } = useForm<FormData>({
        defaultValues,
        mode: 'onChange'
    })
    const { errors } = formState
    const watchType = watch('type', props.type)

    const currentCountry = selectedCountry || props.country
    const showBusinessProvinces = currentCountry === 'IT' && watchType === 'distributor' && !(currentBusinessProvincesForForm.length === 0 && !canAdmin)
    const showDistributorsSelection = canAdmin && ['installer', 'freelance'].some(i => i === watchType)

    const searchQuery = debouncedBusinessName ? { businessName: debouncedBusinessName } : currentCountry === 'IT' ? { province: props.province } : {}

    const distributorsHook = useDistributors(showDistributorsSelection ? {
        country: props.country,
        ...searchQuery,
        sortBy: '+businessName'
    } : {})

    const isLoadingDistributors = distributorsHook.loading || debouncedBusinessName !== businessName

    const provinces = useMemo(() => {
        const currentCountry = selectedCountry || props.country
        const filtered: CountryRegion[] = countryRegionData.filter((item: CountryRegion) => item.countryShortCode === currentCountry)

        if (filtered.length > 0) {
            if (filtered[0].countryShortCode !== 'IT') {
                return filtered[0].regions.map(region => ({ name: region.name, value: region.name }))
            }

            const provinces = italia.regioni.map((region: any) => region.capoluoghi.map((name: string, index: number) => (
                { name: `${region.nome} - ${name}`, value: name }
            )))

            return _.flatMap(provinces)
        }
        return []
    }, [props.country, selectedCountry])

    const onSubmit: SubmitHandler<FormData> = async (form: Object) => {

        let body = _.omitBy(form, _.isUndefined)

        if (canAdmin) {
            body.distributors = body?.distributor?.value && body.distributor.value !== noneDistributor.value ? [body.distributor.value] : []
            body = _.omit(body, 'distributor')
            body.businessProvinces = body.businessProvinces ? body.businessProvinces.map((select: {
                value: string
            }) => ({
                country: selectedCountry,
                province: select.value
            })) : []
            try {
                await execute(() => adminHook.update({ id: props._id, data: body }))
                onClose && onClose()
            } catch (error: any) {
                dispatch(notify('error', 'companyUpdateError', 5000))
            }
        } else {
            body = _.omit(body, 'distributor', 'businessProvinces')
            try {
                await execute(() => update(body))
                dispatch(reloadProfile())
            } catch (error: any) {
                dispatch(notify('error', 'companyUpdateError', 5000))
            }
        }
    }

    function isRequired(field: string) {
        const type = props.type
        let requiredFields
        if (type === 'freelance')
            requiredFields = ['country', 'province']
        else
            requiredFields = ['businessName', 'address', 'city', 'zipCode', 'email', 'phoneNumber', 'country', 'province', 'taxExempt']
        return requiredFields.includes(field)
    }

    const isFreeLance = props.type === 'freelance'
    const isPecCuuToShow = canAdmin && !isFreeLance && isItalian(props.country)
    const isFiscalCodeToShow = isFreeLance && isItalian(props.country)
    const isVatNumberToShow = belongsToEurope(props.country) && !isFreeLance
    const isPromoCodeVisible = canAdmin && watchType === 'installer'
    const taxExemptValues = ['none', 'reverse', 'exempt']

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card pending={loading}>
                <Card.Body>
                    <div className="grid grid-cols-6 gap-4">
                        <Input
                            containerClass="col-span-6 sm:col-span-3 lg:col-span-6"
                            id="company-business-name"
                            name="businessName"
                            label={t('registrationCompany')}
                            type="text"
                            disabled={!canAdmin}
                            invalid={!!errors.businessName}
                            ref={register({ required: isRequired('businessName') })}
                            required
                        />
                        <Input
                            containerClass="col-span-6 sm:col-span-3 lg:col-span-3"
                            id="company-address"
                            name="address"
                            label={t('registrationStreetAddress')}
                            type="text"
                            invalid={!!errors.address}
                            disabled={!canAdmin && !companyAdmin}
                            ref={register({ required: isRequired('address') })}
                            show={!isFreeLance}
                            required
                        />
                        <Input
                            containerClass="col-span-6 sm:col-span-3 lg:col-span-3"
                            id="company-city"
                            name="city"
                            label={t('registrationCity')}
                            type="text"
                            invalid={!!errors.city}
                            disabled={!canAdmin && !companyAdmin}
                            ref={register({ required: isRequired('city') })}
                            show={!isFreeLance}
                            required
                        />
                        <Select
                            containerClass={clsx('col-span-3', {
                                'lg:col-span-2': !isFreeLance
                            })}
                            id="company-province"
                            name="province"
                            label={t('registrationProvince')}
                            disabled={!canAdmin && !companyAdmin}
                            ref={register({ required: isRequired('province') })}
                            required
                        >
                            {provinces.map((province: any) => <option key={province.value}
                                value={province.value}>{province.name}</option>)}
                        </Select>
                        <Input
                            containerClass="col-span-3 lg:col-span-2"
                            id="company-zip-code"
                            name="zipCode"
                            invalid={!!errors.zipCode}
                            label={t('registrationZipCode')}
                            type="text"
                            disabled={!canAdmin && !companyAdmin}
                            ref={register({ required: isRequired('zipCode') })}
                            show={!isFreeLance}
                            required
                        />
                        <Select
                            containerClass={clsx('col-span-3', {
                                'lg:col-span-2': !isFreeLance
                            })}
                            id="company-country"
                            name="country"
                            label={t('registrationCountry')}
                            ref={register({ required: isRequired('country') })}
                            onChange={(event) => setSelectedCountry(event.currentTarget.value)}
                            disabled
                            required
                        >
                            {availableCountries.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                        </Select>
                        <Input
                            containerClass="col-span-6 sm:col-span-3"
                            id="company-email"
                            name="email"
                            invalid={!!errors.email}
                            label={t('registrationEmail')}
                            type="email"
                            disabled={!canAdmin}
                            ref={register({
                                required: isRequired('email'),
                                validate: testEmail
                            })}
                            show={!isFreeLance}
                            required
                        >
                            {errors.email && (
                                <Input.Alert>
                                    {t('invalidEmail')}
                                </Input.Alert>
                            )}
                        </Input>
                        <PhoneWithCountry
                            id="company-phone-number"
                            label={t('registrationPhone')}
                            containerClass="col-span-6 sm:col-span-3"
                            name="phoneNumber"
                            invalid={!!errors.phoneNumber}
                            control={control}
                            rules={{ required: isRequired('phoneNumber') }}
                            disabled={!canAdmin && !companyAdmin}
                            show={!isFreeLance}
                        />
                        <Input
                            containerClass="col-span-6"
                            id="company-vat-number"
                            name="vatNumber"
                            label={t('registrationVatNumber')}
                            type="text"
                            show={isVatNumberToShow}
                            disabled
                            ref={register}
                        />
                        <Input
                            containerClass="col-span-6"
                            id="fiscalCode"
                            name="fiscalCode"
                            label={t('registrationFiscalCode')}
                            type="text"
                            ref={register}
                            disabled
                            show={isFiscalCodeToShow}
                        />
                        <PecCuu show={isPecCuuToShow} register={register} errors={errors} getValues={getValues} />
                        <Select
                            containerClass="col-span-6"
                            id="company-tax-exempt"
                            name="taxExempt"
                            label={t('registrationTaxExempt')}
                            show={canAdmin && !isFreeLance}
                            ref={register}
                            required
                        >
                            {taxExemptValues.map(te => <option key={te} value={te}>{t(`taxexempt${te}`)}</option>)}
                        </Select>
                        <Radio
                            containerClass="col-span-6"
                            id="admin-company-type"
                            ref={register}
                            name="type"
                            options={companyTypeOptions(props.type).map(o => ({ ...o, label: t(o.label) }))}
                            label={t('companyType')}
                            horizontal
                            disabled={isFreeLance}
                            show={canAdmin}
                        />
                        <Checkbox
                            containerClass="col-span-6"
                            label={t('ignoreLoyaltyScore')}
                            id="ignoreLoyaltyScore"
                            name="ignoreLoyaltyScore"
                            show={canAdmin && !isFreeLance}
                            ref={register}
                        />
                        <Select
                            id="admin-status"
                            containerClass="col-span-6"
                            name="status"
                            label={t('status')}
                            ref={register}
                            show={canAdmin}
                        >
                            {['unverified', 'verified', 'invalid'].map((status) => (
                                <option key={status} value={status}>{t(status)}</option>
                            ))}
                        </Select>
                        <PromoCode
                            show={isPromoCodeVisible}
                            register={register}
                        />
                        <DistributorChoice
                            helpMessage=""
                            noOptionsMessage={t('listSearchableNoItemTitle')}
                            control={control}
                            onSearch={(search: string) => {
                                setBusinessName(search)
                            }}
                            distributors={distributorsHook.areaDistributors}
                            isLoading={isLoadingDistributors}
                            invalid={!!errors.distributor}
                            rules={{ required: !canAdmin }}
                            show={showDistributorsSelection}
                            containerClass="col-span-6"
                        />
                        <FormSelect
                            id="admin-company-business-provinces"
                            name="businessProvinces"
                            label={t('businessProvinces')}
                            noOptionsMessage={t('noOptions')}
                            placeholder={t('select')}
                            primaryColor="var(--color-brand-600)"
                            maxMenuHeight={160}
                            control={control}
                            isClearable={false}
                            disabled={!canAdmin}
                            options={provinces.map((province: any) => ({
                                value: province.value,
                                label: province.value
                            }))}
                            show={showBusinessProvinces}
                            containerClass="col-span-6"
                        />
                        <AdminNotes show={canAdmin} register={register} />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className={clsx('flex', onClose ? 'justify-between' : 'justify-end')}>
                        {onClose && (
                            <Button text={t('close')} onClick={onClose} color="white" type="button" />
                        )}
                        <Button
                            disabled={(!canAdmin && !companyAdmin)}
                            color="primary"
                            text={t('update')} type="submit" pending={loading} />
                    </div>
                </Card.Footer>
            </Card>
        </form>
    )
}