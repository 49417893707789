import React, { useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '../../common/Button';
import Input from '../../common/Input/Input';
import Card from '../../common/Card/Card'

export interface UserSearchFormData {
    email?: string
    phoneNumber?: string
    name?: string
    surname?: string
}

interface UserSearchFormProps {
    pending?: boolean,
    onSubmit?: (params: UserSearchFormData) => void
}

type SearchByValues = 'email' | 'phone' | 'fullname'

const UserSearchForm = (props: UserSearchFormProps) => {
    const { onSubmit, pending = false } = props
    const [searchBy, setSearchBy] = useState<SearchByValues>('email')
    const { register, handleSubmit, getValues } = useForm<UserSearchFormData>();
    const { t } = useTranslation()

    const searchbyOptions: { label: string, value: SearchByValues }[] = [
        {
            label: t('adminUserSearchByEmail'),
            value: 'email'
        },
        {
            label: t('adminUserSearchByPhone'),
            value: 'phone'
        },
        {
            label: t('adminUserSearchByFullname'),
            value: 'fullname'
        }
    ]


    const onSubmitHandler: SubmitHandler<UserSearchFormData> = (params) => {
        if (onSubmit) {
            onSubmit(params)
        }
    }

    const radioGroupOptionClass = (current: boolean) => clsx('rounded bg-gray-50 px-2 py-1 text-sm text-gray-500 cursor-pointer', current && 'bg-gray-500 text-white')
    const fieldNameOrSurnameIsRequired = () => getValues('name')?.length < 3 && getValues('surname')?.length < 3
    return (
        <Card pending={pending}>
            <Card.Body>
                <form className="flex flex-col" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-2">
                        <nav className='flex gap-3'>
                            {searchbyOptions.map((option, index) => (
                                <button
                                    key={`search-${index}`}
                                    type="button"
                                    className={radioGroupOptionClass(option.value === searchBy)}
                                    onClick={() => setSearchBy(option.value)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className="flex flex-col sm:flex-row">
                        <div className="flex-1 focus-within:z-10">
                            {searchBy === 'email' && (
                                <Input
                                    inputClasses='rounded-b-none border-gray-100 shadow-none sm:rounded-r-none sm:rounded-bl-md'
                                    type="text"
                                    name="email"
                                    id="user-email"
                                    placeholder={t('adminUserSearchByEmailPlaceholder')}
                                    autoComplete="off"
                                    iconClass="pl-4 sm:pl-6"
                                    ref={register({
                                        required: true,
                                        minLength: 5
                                    })}
                                    minLength={5}
                                    required
                                />
                            )}
                            {searchBy === 'phone' && (
                                <Input
                                    inputClasses='rounded-b-none border-gray-100 shadow-none sm:rounded-r-none sm:rounded-bl-md'
                                    type="text"
                                    name="phoneNumber"
                                    id="user-phone"
                                    placeholder={t('adminUserSearchByPhonePlaceholder')}
                                    autoComplete="off"
                                    iconClass="pl-4 sm:pl-6"
                                    ref={register({
                                        required: true,
                                        minLength: 5
                                    })}
                                    minLength={5}
                                    required
                                />
                            )}
                            {searchBy === 'fullname' && (
                                <div className="grid grid-cols-[1fr,auto,1fr]">
                                    <Input
                                        inputClasses='rounded-r-none border-gray-100 border-r-transparent shadow-none'
                                        type="text"
                                        name="name"
                                        id="user-name"
                                        placeholder={t('adminUserSearchByNamePlaceholder')}
                                        autoComplete="off"
                                        iconClass="pl-4 sm:pl-6"
                                        ref={register({
                                            required: fieldNameOrSurnameIsRequired(),
                                            minLength: 3
                                        })}
                                    />
                                    <div className="w-[1px] bg-gray-100" />
                                    <Input
                                        inputClasses='rounded-none border-gray-100 border-l-transparent shadow-none'
                                        type="text"
                                        name="surname"
                                        id="user-surname"
                                        placeholder={t('adminUserSearchBySurnamePlaceholder')}
                                        autoComplete="off"
                                        iconClass="pl-4 sm:pl-6"
                                        ref={register({
                                            required: fieldNameOrSurnameIsRequired(),
                                            minLength: 3
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                        <Button
                            data-testid="search-button"
                            color="primary"
                            otherButtonClasses="rounded-t-none capitalize focus:ring-offset-0 sm:rounded-l-none sm:rounded-tr-md sm:-ml-px"
                            text={t('search')}
                            icon={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                            type="submit"
                            pending={pending}
                        />
                    </div>
                </form>
            </Card.Body>
        </Card >
    )
}

export default UserSearchForm