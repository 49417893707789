import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ApplicationRole, getApplicationRoles } from '../profile/selectors'
import { NotAuthorized } from '../notAuthorized/NotAuthorized'
import _ from 'lodash'

interface PrivateRouteProps extends RouteProps {
    allowedTo: ApplicationRole[]
}
const isAllowed = (applicationRoles: ApplicationRole[], allowedTo: ApplicationRole[]) => _.intersection(allowedTo, applicationRoles).length > 0

function handleAuthorizationAndRender(
    applicationRoles: ApplicationRole[],
    allowedTo: ApplicationRole[],
    children: any
) {
    return ({ location }: any) => {
        if (_.isEqual(applicationRoles, [ApplicationRole.NotLogged])) {
            return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
        if (isAllowed(applicationRoles, allowedTo)) {
            return children
        }
        return <NotAuthorized />
    }
}

export const PrivateRoute = ({ children, allowedTo, path, ...rest }: PropsWithChildren<PrivateRouteProps>) => {
    const applicationRoles = useSelector(getApplicationRoles)
    return (
        <Route {...rest} path={path} render={handleAuthorizationAndRender(applicationRoles, allowedTo, children)} />
    )
}
