import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import clsx from 'clsx';
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { Spinner } from '../../common/Spinner';
import { certificationMapper } from '../../profile/utils';
import { useAdminUserMutation } from '../../hooks/adminUserMutation';

type UserCertificationProps = {
    userId?: string,
    label: string,
    value?: number,
    onUpdate?: () => void,
}

const certificationOptions = [
    { name: '-', value: 0 },
    { name: 'Selected', value: 1 },
    { name: 'Silver', value: 2 },
    { name: 'Gold', value: 3 },
    { name: 'Specialist', value: 4 },
]

const UserCertification: React.VFC<UserCertificationProps> = (props) => {
    const { userId, label, value = 0, onUpdate } = props
    const [editing, setEditing] = useState(false)
    const [level, setLevel] = useState(value)
    const { t } = useTranslation()
    const { update } = useAdminUserMutation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()

    const changeLevelHandler = () => (ev: React.ChangeEvent<HTMLSelectElement>) => setLevel(parseInt(ev.currentTarget.value))

    const updateLevelHandler = () => async () => {
        if (!userId) {
            return
        }

        try {
            await execute(() => update(
                {
                    id: userId,
                    body: { certificationLevel: level }
                }
            ))
            setEditing(false)
            onUpdate && onUpdate()
        } catch (error: any) {
            dispatch(notify('error', 'userUpdateError', 5000))
        }
    }

    const containerClass = clsx(
        'py-2 px-4 sm:grid sm:py-[calc(1.25rem-2px)] sm:grid-cols-3 sm:gap-4 sm:px-6 border-2 border-dashed border-transparent',
        editing && '!border-brand-200'
    )

    return (
        <div className="relative">
            <Spinner show={loading} size={4} />
            <div className={containerClass}>
                <dt className="text-sm font-medium text-gray-500">{label}</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {!editing ? (
                        <span className="flex-grow">{t(certificationMapper[value])}</span>
                    ) : (
                        <span className="flex-grow">
                            <select onChange={changeLevelHandler()} className="py-0 pl-0 text-sm border-0 focus:!border-none focus:!shadow-none focus:ring-transparent" defaultValue={level}>
                                {certificationOptions.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                    )}

                    <span className="ml-4 flex-shrink-0">
                        {!editing ? (
                            <button
                                onClick={() => setEditing(true)}
                                type="button"
                                className="bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                            >
                                {t('edit')}
                            </button>
                        ) : (
                            <div className="ml-4 flex-shrink-0 flex space-x-2">
                                <button
                                    onClick={() => setEditing(false)}
                                    type="button"
                                    className="bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                                >
                                    {t('cancel')}
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button
                                    onClick={updateLevelHandler()}
                                    type="button"
                                    className="bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                                >
                                    {t('confirm')}
                                </button>
                            </div>
                        )}
                    </span>
                </dd>
            </div>
        </div>
    )
}

export default UserCertification