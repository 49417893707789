import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { billingEpics } from '../../billing/epics'
import { billingReducer as billing } from '../../billing/reducer'
import { sendFeedback$ } from '../../common/Feedback/epics'
import { checkLogin$, userGroups$, userGroupsRemove$ } from '../../login/epics'
import { loginReducer as login } from '../../login/reducer'
import loyaltyProgram$ from '../../loyaltyProgram/epics'
import { loyaltyProgramReducer as loyaltyProgram } from '../../loyaltyProgram/reducer'
import { discardNotification$ } from '../../notification/epic'
import { notificationReducer as notifications } from '../../notification/reducer'
import { acceptCompanyInvite$, inviteCompanyMember$, loadCompanyProfile$, loadProfile$, rejectCompanyInvite$, removeCompanyMember$, updateMember$, uploadProfileImage$ } from '../../profile/epics'
import { profileReducer as profile } from '../../profile/reducer'
import { buyGatewayLicense$ } from '../../services/gatewayLicenses/epics'
import { getAvailableVoices$, getLicense$, getLicenses$, invalidLicense$, saveLicense$ } from '../../services/licenseEpics'
import { licensesReducer as licenses, selectedLicenseReducer as selectedLicense } from '../../services/licenseReducer'
import { buyVoices$ } from '../../services/voiceEpics'
import { voicesReducer as voices } from '../../services/voiceReducer'
import { firstStepsReducer as firstSteps } from '../../profile/FirstSteps/reducer'

export const appReducers = combineReducers({
    login,
    licenses,
    voices,
    notifications,
    selectedLicense,
    profile,
    billing,
    loyaltyProgram,
    firstSteps
})

let epics: any[] = [discardNotification$]

epics.push(
    checkLogin$,
    userGroups$,
    userGroupsRemove$,
    getLicenses$,
    getLicense$,
    saveLicense$,
    invalidLicense$,
    getAvailableVoices$,
    loadProfile$,
    loadCompanyProfile$,
    uploadProfileImage$,
    buyVoices$,
    buyGatewayLicense$,
    billingEpics,
    loyaltyProgram$,
    sendFeedback$,
    inviteCompanyMember$,
    removeCompanyMember$,
    acceptCompanyInvite$,
    rejectCompanyInvite$,
    updateMember$
)

export const rootEpics = combineEpics(
    ...epics
)