import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { CompanyRole, getCompanyRole } from "../profile/selectors"
import { NotAuthorized } from '../notAuthorized/NotAuthorized'

export const CompanyRoleRoute = ({ children, allowedTo }: PropsWithChildren<{allowedTo: CompanyRole[]}>) => {
    const userCompanyRole = useSelector(getCompanyRole)
    const isCompanyRoleAllowed = () => allowedTo.includes(userCompanyRole)
    if (isCompanyRoleAllowed()) 
        return <>{children}</> 
    return <NotAuthorized />
}