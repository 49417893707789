import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { Company, User } from '../../profile/selectors'
import { RequestParams, useRequest } from '../request'
import { USERS_PATH } from '../utils'

type UserSearchProfileType = Pick<User, '_id' | 'email' | 'certificationLevel' | 'name' | 'surname' | 'phoneNumber'>
type UserSearchCompanyType = Partial<Pick<Company, 'status' | 'businessName' | 'type' | '_id'>>

export type UserSearchType = UserSearchProfileType & UserSearchCompanyType & {
    acceptedMarketing?: boolean
    companyId?: string,
}

const fetchOptions = {
    refetchOnWindowFocus: false,
    retry: false
}

interface Params {
    email?: string
    name?: string,
    surname?: string,
    phoneNumber?: string,
    limit?: string
}

export const useAdminUsersSearch = () => {
    const [users, setUsers] = useState<UserSearchType[]>([])
    const [query, setQuery] = useState<Params>({})
    const [error, setError] = useState<ErrorConstructor>()

    const requestParams: RequestParams = {
        path: USERS_PATH,
        query: { ...query, limit: '10' }
    }

    const { request: searchUsers } = useRequest<UserSearchType[]>(requestParams)

    const resetSearch = useCallback(() => {
        setQuery({})
        setUsers([])
    }, [setQuery, setUsers])

    const { refetch, isFetching } = useQuery(
        [requestParams],
        searchUsers,
        {
            onSuccess: (data) => {
                if (data && data?.body) setUsers(data.body)
            },
            onError: (err: ErrorConstructor) => {
                setError(err)
            },
            enabled: Object.keys(query).length !== 0,
            ...fetchOptions
        }
    )

    return {
        params: query,
        setParams: setQuery,
        refetch,
        pending: isFetching,
        users,
        error,
        resetSearch
    }
}
