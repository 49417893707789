import React from 'react'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import { Company } from '../../profile/selectors'
import { useTranslation } from 'react-i18next'


export function AddressTab(props: {
    countryItem: JSX.Element,
    company: Company,
    companyIsADistributor: boolean
}) {
    const { t } = useTranslation()
    const { company, companyIsADistributor, countryItem } = props
    const {
        address,
        zipCode,
        city,
        province,
        businessProvinces = []
    } = company

    const readOnlyBusinessProvinces = businessProvinces.map((bp: any) => bp.province).join(', ')

    return <DescriptionList>
        <DescriptionList.Item label={t('registrationAddress')} value={address} />
        <DescriptionList.Item label={t('registrationZipCode')} value={zipCode} />
        <DescriptionList.Item label={t('registrationCity')} value={city} />
        <DescriptionList.Item label={t('registrationProvince')} value={province} />
        {countryItem}
        <DescriptionList.Item show={companyIsADistributor} label={t('businessProvinces')} value={readOnlyBusinessProvinces} />
    </DescriptionList>
}