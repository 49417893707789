import React from 'react'
import { useTranslation } from 'react-i18next'
import { DotsHorizontalIcon } from '@heroicons/react/outline'
import Card from './Card/Card'

interface MoreResultsProps {
    show: boolean,
    limit: number
}

const MoreResults = (props: MoreResultsProps) => {
    const { show = false, limit } = props
    const { t } = useTranslation()

    if (!show) {
        return null
    }

    return (
        <Card className='mt-6 bg-yellow-50 text-yellow-600'>
            <Card.Body>
                <div className="flex flex-col items-center justify-center">
                    <DotsHorizontalIcon className="w-8" data-testid="dots-horizontal-icon" />
                    <span className="text-sm">{`${t('adminSearchLimitedResults1')} (${limit}). ${t('adminSearchLimitedResults2')}`}</span>
                </div>
            </Card.Body>
        </Card>
    )
}

export default MoreResults