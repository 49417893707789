import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Input } from '../../common'
import { useCompanySearchFormContext } from './useCompanySearchFormContext';

type SearchByValues = 'businessName' | 'email'

const SwitchOptionButton = ({ label, value, isSelected, onClick }: any): ReactElement => <button
    data-testid="search-by-button"
    key={value}
    type="button"
    className={clsx(styles.searchBy, { 'bg-gray-500 text-white': isSelected })}
    onClick={onClick}
>
    {label}
</button>

const CompaniesSearchInput = () => {
    const [searchBy, setSearchBy] = useState<SearchByValues>('businessName')
    const { t } = useTranslation()
    const { data, setData } = useCompanySearchFormContext()

    const changeSearchByHandler = (value: SearchByValues) => () => {
        setSearchBy(value)
        value === 'businessName' ? setData({ ...data, email: '' }) : setData({ ...data, businessName: '' })
    }
    const searchingByBusinessName = searchBy === 'businessName'
    const searchingByEmail = searchBy === 'email'
    
    return (
        <>
            <nav className={styles.searchByNav}>
                <SwitchOptionButton
                    value={'businessName'}
                    label={t('companiesSearchByName')}
                    isSelected={searchBy === 'businessName'}
                    onClick={changeSearchByHandler('businessName')}
                />
                <SwitchOptionButton
                    value={'email'}
                    label={t('companiesSearchByEmail')}
                    isSelected={searchBy === 'email'}
                    onClick={changeSearchByHandler('email')}
                />
            </nav>
            {searchingByBusinessName && (
                <Input
                    inputClasses={styles.searchInput}
                    type="text"
                    name="businessName"
                    id="companies-search-business-name"
                    data-testid="companies-search-business-name"
                    placeholder={t('companiesSearchByNamePlaceholder')}
                    autoComplete="off"
                    minLength={3}
                    onChange={(e) => setData({
                        ...data,
                        businessName: e.target.value,
                    })}
                    required
                />
            )}
            {searchingByEmail && (
                <Input
                    inputClasses={styles.searchInput}
                    type="text"
                    name="email"
                    id="companies-search-email"
                    data-testid="companies-search-email"
                    placeholder={t('companiesSearchByEmailPlaceholder')}
                    autoComplete="off"
                    minLength={5}
                    onChange={(e) => setData({
                        ...data,
                        email: e.target.value
                    })}
                    required
                />
            )}
        </>
    )
}

const styles = {
    searchByNav: 'flex gap-x-3',
    searchBy: 'relative rounded bg-gray-50 px-2 py-1 text-sm text-gray-500 cursor-pointer',
    searchByOption: 'absolute inset-0 z-10 invisible',
    searchInput: 'border-gray-100 shadow-none'
}

export default CompaniesSearchInput