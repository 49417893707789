import React, { useState, useEffect, useCallback, createRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { GoogleMap, Marker, MarkerClusterer, Circle, useLoadScript } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { Location, Panel } from '../types'
import { isMobile } from 'react-device-detect'
import {
    ChipIcon,
    QrcodeIcon,
    StatusOfflineIcon,
    StatusOnlineIcon,
    TagIcon,
} from '@heroicons/react/outline'
import { mapPanelConnectionStatus, mapPanelConnectionType } from '../utils'

const defaultMapsCenter = { lat: 41.893607, lng: 12.482817 }

export type Position = { lat: number | null, lng: number | null, accuracy: number | null }

export interface MarkerInfo extends Position {
    panel?: Panel
}

export const validPosition = (position: Position) => position && position.lat != null && position.lng != null

export const validLocation = (location: Location) => location && location.coordinates[0] != null && location.coordinates[0] !== 0 && location.coordinates[1] != null && location.coordinates[1] !== 0

export const positionToLocation = (position: Position): Location => {
    return ({
        type: 'Point',
        coordinates: [position.lng as number, position.lat as number]
    })
}

export const locationToPosition = (location: Location): Position => {
    return ({ lat: location.coordinates[1], lng: location.coordinates[0], accuracy: null })
}

const useGeolocation = () => {
    const [state, setState] = useState({
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: null,
        timestamp: Date.now()
    })
    const onEvent = useCallback(
        (event: any) => {
            setState({
                accuracy: event.coords.accuracy,
                altitude: event.coords.altitude,
                altitudeAccuracy: event.coords.altitudeAccuracy,
                heading: event.coords.heading,
                latitude: event.coords.latitude,
                longitude: event.coords.longitude,
                speed: event.coords.speed,
                timestamp: event.timestamp
            })
        },
        []
    )
    useEffect(
        () => {
            navigator?.geolocation?.getCurrentPosition(onEvent)
        },
        [onEvent]
    )
    return state
}

export default function Map(props: { containerClassname: string, onSelectPosition?: (postion: Position) => void, markers?: MarkerInfo[], mode?: 'show' | 'edit' | 'add', onEdit?: (panel: Panel) => void }) {
    const { onSelectPosition, containerClassname, markers = [], mode = 'show' } = props
    const [libraries] = useState(['places'])
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDQabJb1-k7kXAis_ToHVoMwAcaghiW_Fc',
        libraries: libraries as Libraries
    })
    if (loadError) console.log('error loading GoogleMapsApi')

    const [currentMarkers, setCurrentMarkers] = useState<MarkerInfo[]>([])

    const [mapsCenter, setMapsCenter] = useState<Position>({ lat: null, lng: null, accuracy: null })
    const [here, setHere] = useState<Position>({ lat: null, lng: null, accuracy: null })
    const { latitude, longitude, accuracy } = useGeolocation()

    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [infoWindow, setInforWindow] = useState<google.maps.InfoWindow | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (mode === 'show' || mode === 'edit') {
            setCurrentMarkers(markers as Position[])
        }
    }, [markers, mode])

    const onLoad = useCallback((map) => {
        setMap(map)
        setInforWindow(new google.maps.InfoWindow({
            content: '',
            pixelOffset: new google.maps.Size(0, -50)
        }))
    }, [setMap, setInforWindow])

    useEffect(() => {
        if (isLoaded && map) {
            const bounds = new window.google.maps.LatLngBounds()
            if (currentMarkers.length) {
                if (currentMarkers.length === 1) {
                    setMapsCenter(currentMarkers[0] as Position)
                } else if (currentMarkers.length > 1) {
                    currentMarkers.forEach(p => bounds.extend(p as google.maps.LatLngLiteral))
                    if (map) {
                        map.fitBounds(bounds)
                    }
                }
            }
        }
    }, [currentMarkers, isLoaded, map, setMapsCenter])

    const onUnmount = React.useCallback(() => {
        setMap(null)
    }, [])

    useEffect(() => {
        const h = { lat: latitude, lng: longitude, accuracy }
        if (!currentMarkers.length && validPosition(h) && !validPosition(here)) {
            // no positions provided so set here is the maps center
            setHere(h)
            setMapsCenter(h)
            if (mode === 'add' || mode === 'edit') {
                // auto set marker here if selecting a position
                setCurrentMarkers([h as Position])
                if (onSelectPosition) {
                    onSelectPosition(h as Position)
                }
            }
        }
    }, [latitude, longitude, accuracy, here, onSelectPosition, currentMarkers, mode])

    useEffect(() => {
        //close if array changes
        infoWindow && infoWindow.close()
    }, [markers, infoWindow])

    const onDragEnd = useCallback((event) => {
        const position = { lat: event.latLng.lat(), lng: event.latLng.lng(), accuracy: null }
        setCurrentMarkers([position])
        if (onSelectPosition) {
            onSelectPosition(position)
        }
    }, [setCurrentMarkers, onSelectPosition])

    const arrLength = markers.length
    const [markerRefs, setMarkerRefs] = useState([])
    React.useEffect(() => {
        setMarkerRefs(refs => (
            Array(arrLength).fill(0).map((_, i) => refs[i] || createRef())
        ))
    }, [arrLength])

    let center = validPosition(mapsCenter) ? mapsCenter : defaultMapsCenter
    return isLoaded ? <GoogleMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerClassName={containerClassname}
        center={center as google.maps.LatLngLiteral}
        zoom={16}
        options={{
            disableDefaultUI: true,
            zoomControl: true,
            fullscreenControl: true,
            scrollwheel: !isMobile,
            styles: [{
                featureType: 'poi',
                stylers: [
                    { visibility: 'off' }
                ]
            }]
        }}
        onClick={() => { infoWindow && infoWindow.close() }}
    >
        <MarkerClusterer maxZoom={15}>
            {(clusterer) => currentMarkers.map((m, i) => {
                const { accuracy, panel, ...position } = m
                const marker = <Marker
                    ref={markerRefs[i]}
                    clusterer={clusterer}
                    draggable={mode === 'edit' || mode === 'add'}
                    key={`maps-marker-${i}`}
                    position={position as google.maps.LatLngLiteral}
                    onDragEnd={onDragEnd}
                    onClick={mode === 'show' && infoWindow && (panel) ? (e) => {
                        const connectionStatus = mapPanelConnectionStatus(panel.connection)
                        const connectionType = mapPanelConnectionType(panel.connection)
                        const content = ReactDOMServer.renderToString(
                            <div className="flex flex-col w-80">
                                {panel.name ? <p className='font-bold text-base'>{panel.name}</p> : null}
                                {panel.description ? <p className='text-sm text-gray-800 line-clamp-3'>{panel.description}</ p> : null}
                                <div className="flex mt-2 py-1 border-b border-t">
                                    <p className="flex-1 w-2/4 flex items-center">
                                        <ChipIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                            aria-hidden="true" />
                                        <span className="text-gray-600 text-xs">{panel.firmwareVersion || '-'}</span>
                                    </p>
                                    <p className="flex-1 w-2/4 flex items-center">
                                        <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                            aria-hidden="true" />
                                        <span className="text-gray-600 text-xs">{panel.serialNumber}</span>
                                    </p>
                                </div>
                                <div className="flex py-1 border-b">
                                    <p className="flex-1 w-2/4 flex items-center">
                                        {connectionStatus === 'Online' ?
                                            <>
                                                <StatusOnlineIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                                    aria-hidden="true" />
                                                <span className="text-gray-600 text-xs">{t(`connectionType${connectionType}`)}</span>
                                            </>
                                            :
                                            <>
                                                <StatusOfflineIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                                    aria-hidden="true" />
                                                <span className="text-gray-600 text-xs">{t('Notavailable')}</span>
                                            </>
                                        }
                                    </p>
                                    <p className="flex-1 w-2/4 flex items-center">
                                        <TagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                            aria-hidden="true" />
                                        <span className="text-gray-600 text-xs">{panel.model || '-'}</span>
                                    </p>
                                </div>
                                <div className="text-center">
                                    <button id="map-panel-info" className="text-sm w-full py-3 text-brand-500" type="button">Edit Panel</button>
                                </div>
                            </div>
                        )
                        infoWindow.setContent(content)
                        infoWindow.setPosition(e.latLng)
                        infoWindow.open(map)

                        const editButton = document.getElementById('map-panel-info')
                        editButton?.addEventListener('click', () => props.onEdit && props.onEdit(panel))
                    } : undefined}
                />
                return m.accuracy ?
                    <>
                        <Circle
                            key={`maps-marker-accuracy-circle-${i}`}
                            options={{
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 1,
                                fillColor: "#FF0000",
                                fillOpacity: 0.35,
                            }}
                            center={position as google.maps.LatLngLiteral}
                            radius={accuracy || 0}
                        />
                        {marker}
                    </>
                    :
                    marker
            })}
        </MarkerClusterer>
    </GoogleMap> : null
}