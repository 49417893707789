import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    HomeIcon,
    OfficeBuildingIcon,
    ShoppingCartIcon,
    UserIcon,
    BookOpenIcon,
    AdjustmentsIcon,
    CubeIcon,
    ChipIcon,
    AcademicCapIcon,
    GiftIcon,
    BadgeCheckIcon
} from '@heroicons/react/outline'

import { ApplicationRole, CompanyRole, CompanyType } from '../../../profile/selectors'
import MenuKseniaWorld from '../../../common/Menu/MenuKseniaWorld'
import { BaseMenuNav, Navigation } from '../../../common/Menu/BaseMenuNav'
import _ from 'lodash'

interface Props {
    companyType: CompanyType
    releaseNotesUrl: string,
    setSidebarOpen: (open: boolean) => void
    applicationRoles: ApplicationRole[]
    companyRole: CompanyRole
}

export const MenuNav: VFC<Props> = (props) => {
    const { setSidebarOpen, applicationRoles, companyRole, companyType } = props
    const { t } = useTranslation()

    const panelsMenuItem = { name: t('panels'), icon: ChipIcon, navlink: '/panels' }

    const pricesAndCatalogsMenuItem = {
        name: t('prices-and-catalogs'),
        icon: BookOpenIcon,
        navlink: '/prices-and-catalogs'
    }

    const certificationsMenuItem = {
        name: t('productsCertifications'),
        icon: BadgeCheckIcon,
        navlink: '/certifications'
    }

    const trainingMenuItem = {
        name: t('training'),
        icon: AcademicCapIcon,
        navlink: '/training'
    }

    const productsAndDownloadsMenuItem = {
        name: t('documentation-and-software'),
        icon: CubeIcon,
        navlink: '/documentation-and-software'
    }

    const loyaltyMenuItem = {
        name: t('loyaltyProgram'),
        icon: GiftIcon,
        children: [
            { name: t('loyaltyLeaderboards'), navlink: '/loyalty/leaderboards' },
            { name: t('loyaltyInfo'), navlink: '/loyalty/info' }
        ]
    }

    const loyaltyMenuItemVisitor = {
        name: t('loyaltyProgram'),
        icon: GiftIcon,
        navlink: '/loyalty/info'
    }

    const adminMenuItem = {
        name: t('adminMenuItem'),
        icon: AdjustmentsIcon,
        children: [
            { name: t('adminMenuCompanies'), navlink: '/admin/companies' },
            { name: t('adminMenuUsers'), navlink: '/admin/users' },
        ].concat(applicationRoles.includes(ApplicationRole.Helpdesk) ? [{ name: t('adminMenuPanels'), navlink: '/admin/panels' }] : [])
    }

    let advancedMenu: Navigation[] = [
        panelsMenuItem,
        { name: t('services'), icon: ShoppingCartIcon, navlink: '/services' },
        {
            name: t('account'),
            icon: UserIcon,
            children: [
                { name: t('profile'), navlink: '/profile' },
            ]
        }
    ]

    if (['installer', 'distributor'].includes(companyType)) {
        advancedMenu.push(
            {
                name: t('company'),
                icon: OfficeBuildingIcon,
                children: [
                    { name: t('profile'), navlink: '/company' },
                    { name: t('orders'), navlink: '/orders' }
                ]
            })
        if (companyRole === CompanyRole.Admin) {
            advancedMenu[advancedMenu.length - 1].children?.push({ name: t('members'), navlink: '/members' })
        }
    }


    advancedMenu = [
        ...advancedMenu,
        trainingMenuItem,
        loyaltyMenuItem,
        { name: t('downloads') },
        pricesAndCatalogsMenuItem,
        certificationsMenuItem,
        productsAndDownloadsMenuItem,
    ]


    let visitorMenu: Navigation[] = [
        { name: t(`dashboard`), icon: HomeIcon, navlink: '/dashboard' },
        panelsMenuItem,
        {
            name: t(`account`),
            icon: UserIcon,
            children: [
                { name: t(`profile`), navlink: '/profile' }
            ]
        },
        trainingMenuItem,
        pricesAndCatalogsMenuItem,
        loyaltyMenuItemVisitor
    ]

    const menu = companyRole === CompanyRole.Visitor ? visitorMenu : advancedMenu

    if (_.intersection([ApplicationRole.Sales, ApplicationRole.Helpdesk], applicationRoles).length > 0) {
        menu.unshift(adminMenuItem)
    }

    return <BaseMenuNav
        menu={menu}
        setSidebarOpen={(open) => setSidebarOpen(open)}
    >
        <MenuKseniaWorld />
    </BaseMenuNav>
}
