import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { ajax } from 'rxjs/ajax'
import request from 'superagent'
import { BillingActions } from './billing/actions'
import { billingDefaultState, BillingState } from './billing/reducer'
import { appReducers as BticinoAppReducers, rootEpics as BticinoRootEpics } from './brand/bticino/configureStore'
import { appReducers as KseniaAppReducers, rootEpics as KseniaRootEpics } from './brand/ksenia/configureStore'
import { CommonActions } from './common/actions'
import { FeedbackActions } from './common/Feedback/actions'
import { isBticino } from './config'
import { currentAuthenticatedUser, currentSession } from './auth/amplify'
import { LoginActions } from './login/actions'
import { LoginState } from './login/reducer'
import { LogoutActions } from './logout/actions'
import { LoyaltyProgramActions } from './loyaltyProgram/actions'
import { loyaltyProgramDefaultState, LoyaltyProgramState } from './loyaltyProgram/reducer'
import { NotificationActions } from './notification/actions'
import { Notification } from './notification/reducer'
import { LoadProfileActions } from './profile/actions'
import { firstStepsInitialState, FirstStepsState } from './profile/FirstSteps/reducer'
import { UserCompanyProfile } from './profile/selectors'
import { GatewayLicensesActions } from './services/gatewayLicenses/actions'
import { License, LicensesActions } from './services/licenseActions'
import { LicensesState } from './services/licenseReducer'
import { VoicesState } from './services/voiceReducer'
import { VoicesActions } from './services/voicesActions'

/**
 * actions
 */
export type ActionTypes = LoginActions | LogoutActions |
    NotificationActions |
    LicensesActions | CommonActions |
    LoadProfileActions | VoicesActions | BillingActions | GatewayLicensesActions |
    LoyaltyProgramActions | FeedbackActions

/**
 * reducers
 */
export interface State {
    login?: LoginState,
    licenses: LicensesState,
    voices: VoicesState,
    selectedLicense: License,
    notifications: Notification[],
    profile: UserCompanyProfile & { loading: boolean },
    billing: BillingState,
    loyaltyProgram: LoyaltyProgramState,
    firstSteps: FirstStepsState
}

export const defaultState: State = {
    licenses: {
        value: [],
        availableVoices: [],
        search: '',
        pending: false
    },
    notifications: [],
    profile: {
        _id: '',
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        loading: false,
        role: 'employee'
    },
    selectedLicense: {
        id: '',
        alreadyRegistered: false,
        voiceCount: 0,
        voices: []
    },
    voices: {
        openModal: false,
        pending: false
    },
    billing: billingDefaultState,
    loyaltyProgram: loyaltyProgramDefaultState,
    firstSteps: firstStepsInitialState
}

const appReducer = isBticino() ? BticinoAppReducers : KseniaAppReducers
const rootEpics = isBticino() ? BticinoRootEpics : KseniaRootEpics

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        currentSession,
        currentAuthenticatedUser,
        fetchJson: function (url: string, headers?: Object) {
            return ajax.getJSON(url, headers)
        },
        deleteEntity: function (url: string, headers?: Object) {
            return ajax.delete(url, headers)
        },
        post: function (url: string, body: any, headers?: Object) {
            return ajax.post(url, body, {
                'Content-Type': 'application/json',
                ...headers
            } as Object)
        },
        getPDF: function (url: string, token: string) {
            return request.get(url)
                .set('Authorization', token)
                .responseType('blob')
        },
        put: function (url: string, body: any, headers?: Object) {
            return ajax.put(url, body, headers)
        },
        postFile: function (url: string, name: string, file: Buffer, type: string, headers?: Object) {
            const formData = new FormData()
            const blob = new Blob([file], { type })
            formData.append(name, blob)
            return ajax({
                headers,
                method: 'POST',
                url,
                body: formData
            })
        },
        getEnv: function (): string {
            return process.env.NODE_ENV || ''
        },
        getBuildVariant: function (): string {
            return process.env.REACT_APP_BUILD || ''
        },
        getBuildVersion: function (): string {
            return process.env.REACT_APP_BUILD_VERSION || ''
        }
    }
})

/**
 * middlewares
 */
let middlewares: any = [epicMiddleware]

if (process.env.NODE_ENV !== 'production')
    middlewares.push(createLogger())

const middleware = applyMiddleware(...middlewares)

//@ts-ignore
const storeTmp = createStore(appReducer, middleware)

epicMiddleware.run(rootEpics)

export const store = storeTmp
