import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import countryRegionData from 'country-region-data/dist/data-umd'
import { useCompanySearchFormContext } from './useCompanySearchFormContext'
import { countriesWithEnglishName } from '../../common/utils'
import { InputSearchable, InputSearchableOption } from '../../common'

// @ts-ignore
import italia from '@ksenia/italia'
import { CountryRegion } from '../../dashboard/createCompany/utils'

function italianProvinces() {
    let provinces: InputSearchableOption[] = []
    _.forEach(italia.regioni, (region) => {
        for (var i = 0; i < region.capoluoghi.length; ++i) {
            provinces.push({
                value: region.capoluoghi[i],
                label: region.nome + ' - ' + region.capoluoghi[i]
            })
        }
    })
    return provinces
}

const CompaniesSearchCountryFilter = () => {
    const { data, setData } = useCompanySearchFormContext()
    const [provinces, setProvinces] = useState<InputSearchableOption[]>(italianProvinces())

    useEffect(() => {
        if (data.country === 'IT') {
            setProvinces(italianProvinces())
        } else {
            const foundCountries: CountryRegion[] = _.filter(countryRegionData, (country: CountryRegion) => country.countryShortCode === data.country)
            const foundCountry = foundCountries && foundCountries.length > 0 ? foundCountries[0] : null
            const otherCountryProvinces = foundCountry ? foundCountry.regions.map(
                (country) => {
                    return {
                        label: country.name,
                        value: country.name
                    }
                }) : []
            setProvinces(otherCountryProvinces)
        }
    }, [data.country])

    const selectCountryHandler = (country: string) => {
        setData({ ...data, country, province: '' })
    }

    const selectProvinceHandler = (province: string) => {
        setData({ ...data, province })
    }

    return (
        <div className={styles.container} data-testid="country-filter">
            <InputSearchable
                name="country"
                options={countriesWithEnglishName}
                defaultValue=''
                onSelect={selectCountryHandler}
            />
            <InputSearchable
                defaultValue=''
                clearable={true}
                options={provinces}
                disabled={provinces.length === 0}
                onSelect={selectProvinceHandler}
            />
        </div>
    )
}

const styles = {
    container: 'grid grid-cols-2 gap-2',
}

export default CompaniesSearchCountryFilter