import React from 'react'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import { useTranslation } from 'react-i18next'
import { Company, CompanyStatus } from '../../profile/selectors'
import clsx from 'clsx'


const companyStatusClass = (status: CompanyStatus) => clsx('uppercase text-xs rounded px-1 text inline-block', {
    'text-green-500 bg-green-100': status === 'verified',
    'text-yellow-500 bg-yellow-100': status === 'invalid',
    'text-gray-500 bg-gray-100': status === 'unverified'
})

export function CompanyTab(props: {
    countryItem: JSX.Element,
    company: Company
    showStatus: boolean,
}) {
    const { t } = useTranslation()
    const {
        businessName,
        email,
        phoneNumber,
        type,
        note,
        status
    } = props.company

    return <dl className="sm:divide-y sm:divide-gray-200">
        {props.countryItem}
        <DescriptionList.Item
            label={t('registrationCompany')}
            value={businessName}
        />
        <DescriptionList.Item label={t('registrationCompanyType')} value={t(type)} />
        <DescriptionList.Item show={props.showStatus} label={t('status')} value={<span className={companyStatusClass(status)}>{t(status)}</span>} />
        <DescriptionList.Item label={t('email')} value={email} />
        <DescriptionList.Item label={t('registrationPhone')} value={phoneNumber} />
        <DescriptionList.Item label={t('notes')} value={note} />
    </dl>
}