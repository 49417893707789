import React from 'react'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import { useTranslation } from 'react-i18next'
import { CertificationLevel, Company } from '../../profile/selectors'
import clsx from 'clsx'
import { LoyaltyProgramScores } from './LoyaltyProgramScores'

const certificationLevelClass = (certificationLevel?: CertificationLevel) => clsx('uppercase text-xs rounded px-1 text inline-block', {
    'text-white bg-levelSelected': certificationLevel === CertificationLevel.Selected,
    'text-white bg-levelSilver': certificationLevel === CertificationLevel.Silver,
    'text-white bg-levelGold': certificationLevel === CertificationLevel.Gold,
    'text-white bg-levelSpecialist': certificationLevel === CertificationLevel.Specialist
})

const certificationLevelToLabel = (certificationLevel?: CertificationLevel): string => {
    switch (certificationLevel) {
        case CertificationLevel.Selected:
            return 'levelSelected'
        case CertificationLevel.Silver:
            return 'levelSilver'
        case CertificationLevel.Gold:
            return 'levelGold'
        case CertificationLevel.Specialist:
            return 'levelSpecialist'
        default:
            return 'levelNone'
    }
}


export function CertificationTab(props: {
    company: Company
}) {
    const { t } = useTranslation()
    const { company } = props
    const {
        certificationLevel = 0,
        scaledCertificationLevel,
        loyaltyProgramScores = {}
    } = company

    const certLevelClassName = certificationLevelClass(certificationLevel)
    const certLevelValue = t(certificationLevelToLabel(certificationLevel))
    const scaledLevelClassName = certificationLevelClass(scaledCertificationLevel)
    const scaledLevelValue = t(certificationLevelToLabel(scaledCertificationLevel))

    return <dl className="sm:divide-y sm:divide-gray-200">
        <DescriptionList.Item
            label={t('certificationLevel')}
            value={<span className={certLevelClassName}>{certLevelValue}</span>} />
        <DescriptionList.Item
            label={t('scaledCertificationLevel')}
            value={<span className={scaledLevelClassName}>{scaledLevelValue}</span>} />
        <DescriptionList.Item
            label={t('loyaltyProgramScores')}
            value={<LoyaltyProgramScores scores={loyaltyProgramScores} />}
        />
    </dl>
}