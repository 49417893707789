import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BanIcon, PencilIcon } from '@heroicons/react/outline'
import { Tab } from '@headlessui/react'
import { useAdminCompanyMutation } from '../../hooks/adminCompanies'
import { Company } from '../../profile/selectors'
import { notify } from '../../notification/actions'
import { MenuDropDown } from '../../common/MenuDropDown'
import { Modal } from '../../common/Modal'
import { CompanyProfile } from '../../profile/CompanyProfile'
import Card from '../../common/Card/Card'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import clsx from 'clsx'
import { CompanyMembersAdmin } from './CompanyMembersAdmin'
import { CompanyTab } from './CompanyTab'
import { AddressTab } from './AddressTab'
import { BillingTab } from './BillingTab'
import { CertificationTab } from './CertificationTab'

type ActionsType = 'edit' | 'approve' | 'reject'

interface CompaniesItemPropsType {
    data: Company,
    onRefetch: () => void,
    onUpdating?: () => void,
    onUpdated?: () => void,
    actions?: Array<ActionsType>,
    showStatus?: boolean,
    tabsToShow: string[]
}

const tabListClass = 'flex space-x-1 flex-1 overflow-x-scroll hide-scrollbar relative'
const tabClass = ({ selected }: { selected: boolean }) => clsx(
    'w-full py-4 px-4 text-center border-b-2 text-sm',
    selected ? 'border-brand-500 text-brand-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
)

interface ExtendedTabPropsType {
    className: ({ selected }: { selected: boolean }) => string,
    show: boolean,
    value: string
}

interface ExtendedTabPanelsPropsType {
    className: string,
    show: boolean,
    children?: React.ReactElement
}

const ExtendedTab = (props: ExtendedTabPropsType) => {
    const { show, value, className } = props
    if (!show) return null
    return <Tab className={className}>{value}</Tab>
}

const ExtendedTabPanel = (props: ExtendedTabPanelsPropsType) => {
    const { show, className, children } = props
    if (!show) return null
    return <Tab.Panel className={className}>
        {children}
    </Tab.Panel>
}

export function CompaniesItem(props: CompaniesItemPropsType) {
    const {
        data,
        onRefetch,
        actions = ['edit', 'approve', 'reject'],
        showStatus = true,
        onUpdating,
        onUpdated,
        tabsToShow = []
    } = props
    const [show, setShow] = useState(false)
    const { t } = useTranslation()
    const { update } = useAdminCompanyMutation()
    const dispatch = useDispatch()
    const {
        _id,
        businessName,
        country,
        type
    } = data

    const companyIsADistributor = type === 'distributor'

    const startEditHandler = () => {
        setShow(true)
    }

    const cancelEditHandler = () => {
        onRefetch()
        setShow(false)
    }

    const rejectHandler = async (id: string) => {
        if (window.confirm(t('adminCompanyRejectAlert'))) {
            try {
                onUpdating && onUpdating()
                await update({ id, data: { status: 'invalid' } })
                onRefetch()
            } catch (error: any) {
                dispatch(notify('error', error.code))
            } finally {
                onUpdated && onUpdated()
            }
        }
    }

    const itemContextMenu = [
        {
            key: 'edit',
            label: t('edit'),
            onClick: startEditHandler,
            icon: PencilIcon
        },
        {
            key: 'reject',
            label: t('reject'),
            onClick: () => rejectHandler(_id),
            icon: BanIcon
        }
    ].filter(({ key }) => actions.includes(key as ActionsType))

    const countryItem = <DescriptionList.Item
        label={t('registrationCountry')}
        value={<span className="flex items-center "><img alt="" className="w-3 mr-2"
            src={require(`../../images/flags/available/${country.toLowerCase()}.svg`).default} />{country}</span>} />

    const selectedTabIs = (tabName: string): boolean => tabsToShow.includes(tabName)

    return (
        <Tab.Group>
            <Modal open={show} onClose={() => {
                setShow(false)
                onRefetch()
            }} size="lg" title={businessName} modalClass="!pr-0 !pl-0 !pb-0">
                <CompanyProfile {...data} onClose={cancelEditHandler} canAdmin />
            </Modal>
            <Card>
                <Card.Header full otherClasses="flex items-stretch border-b-transparent">
                    <Tab.List className={tabListClass}>
                        <ExtendedTab show={selectedTabIs('company')} className={tabClass} value={t('company')} />
                        <ExtendedTab show={selectedTabIs('address')} className={tabClass} value={t('addressAndOperationAreas')} />
                        <ExtendedTab show={selectedTabIs('billing')} className={tabClass} value={t('billingInfo')} />
                        <ExtendedTab show={selectedTabIs('certification')} className={tabClass} value={t('certificationInfo')} />
                        <ExtendedTab show={selectedTabIs('members')} className={tabClass} value={t('members')} />
                    </Tab.List>
                    <MenuDropDown
                        items={itemContextMenu}
                        buttonClass="flex items-center justify-center min-w-[3rem] rounded-none h-full border-l-gray-100 border-l"
                    />
                </Card.Header>
                <Tab.Panels>
                    <ExtendedTabPanel
                        show={selectedTabIs('company')}
                        className="px-4 py-5 sm:p-0">
                        <CompanyTab
                            countryItem={countryItem}
                            company={data}
                            showStatus={showStatus}
                        />
                    </ExtendedTabPanel>
                    <ExtendedTabPanel
                        show={selectedTabIs('address')}
                        className="px-4 py-5 sm:p-0">
                        <AddressTab
                            countryItem={countryItem}
                            company={data}
                            companyIsADistributor={companyIsADistributor}
                        />
                    </ExtendedTabPanel>
                    <ExtendedTabPanel
                        show={selectedTabIs('billing')}
                        className="px-4 py-5 sm:p-0">
                        <BillingTab
                            company={data}
                            companyIsADistributor={companyIsADistributor}
                        />
                    </ExtendedTabPanel>
                    <ExtendedTabPanel
                        show={selectedTabIs('certification')}
                        className="px-4 py-5 sm:p-0">
                        <CertificationTab company={data} />
                    </ExtendedTabPanel>
                    <ExtendedTabPanel
                        show={selectedTabIs('members')}
                        className="px-4 py-5 sm:p-0">
                        <CompanyMembersAdmin id={_id} />
                    </ExtendedTabPanel>
                </Tab.Panels>
            </Card>
        </Tab.Group>
    )
}

export default CompaniesItem