import { Connection, Model } from './types'
import { compare, coerce } from 'semver'

export type ConnectionType = 'ETHERNET' | 'MOBILE_2G' | 'MOBILE_3G' | 'MOBILE_4G' | 'Notavailable'

export function mapPanelConnectionStatus(connection?: Connection) {
    return connection ? connection.status === 'READY' ? 'Online' : 'Offline' : 'Offline'
}

export function mapPanelConnectionType(connection?: Connection): ConnectionType {
    return connection ? (connection.type || 'Notavailable') : 'Notavailable'
}

const laresWlsModel = 'Centrale Ksenia wls'

export function isALaresWls(model?: Model) {
    return !!model && model === laresWlsModel
}

const validFwVersion = (fw?: string) => fw && fw !== 'NA'

export function compareFwVersions(aFw: string, bFw: string): number {
    if (!validFwVersion(aFw) && !validFwVersion(aFw)) {
        return 0
    }
    if (!validFwVersion(aFw) ) {
        return -1
    }
    if (!validFwVersion(bFw) ) {
        return 1
    }
    return compare(coerce(aFw), coerce(bFw))
}