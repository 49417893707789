import { useState } from 'react'
import { useQuery } from 'react-query'
import { COMPANIES_PATH } from '../utils'
import { CompanyMember, CompanyMemberInvitation } from '../../profile/selectors'
import { RequestParams, useRequest } from '../request'

const fetchOptions = {
    refetchOnWindowFocus: false
}

type Company = {
    id: string
    type: 'installer'
    businessName: string
    address: string
    zipCode: string
    city: string
    country: string
    province: string
    email: string
    phoneNumber: string
    vatNumber?: string
    pec?: string
    cuu?: string
    distributors?: string[]
    actualBrands?: string[]
    members?: CompanyMember[]
    invitations?: CompanyMemberInvitation[]
    status: 'verified' | 'unverified' | 'invalid'
    note?: string
}

export const useAdminCompany = (params: { id: string } = { id: '' }) => {
    const [company, setCompany] = useState<Company | undefined>(undefined)
    const { id } = params

    const requestParams: RequestParams = {
        path: `${COMPANIES_PATH}/${id}`
    }

    const { request: getCompany } = useRequest<Company>(requestParams)

    const { refetch, isFetching, error } = useQuery(
        [requestParams],
        getCompany,
        {
            onSuccess: (data) => {
                if (data && data?.body) setCompany(data.body)
            },
            ...fetchOptions
        }
    )

    return {
        error,
        company,
        loading: isFetching,
        refetch
    } as {
        error: ErrorConstructor
        company: typeof company
        loading: typeof isFetching
        refetch: typeof refetch
    }
}
