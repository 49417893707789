import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { PANELS_PATH, useDebounce } from '../utils'
import { Query, RequestParams, useRequestBuilder } from '../request'
import { Panel } from '../../panels/types'
import { notify } from '../../notification/actions'
import { Filters } from '../../panels/types'
import _ from 'lodash'

type CreatePanel = Pick<Panel, 'serialNumber' | 'name' | 'description' | 'location'>
export type EditPanel = Pick<Panel, | 'name' | 'description' | 'location'> & { mac: string }

export interface Pagination {
    prev: string
    next: string
    first: string
    last: string,
    pages: number,
    current: number,
    count: number
}

interface usePanelsArgs {
    limit?: boolean
    staleTimeQuery?: number,
    projection?: 'full'
}

export const SEARCH_MIN_LENGTH = 3
const SEARCH_DEBOUNCE_DELAY = 500

const LIST_PANELS_DEFAULT_LIMIT = 10

const extractLinks = (linkHeader?: string): { next?: string, prev?: string } => {
    if (!linkHeader)
        return {}
    const urlArray = linkHeader.split(',')
    return urlArray.reduce((acc, cur) => {
        const pattern = /<([^>]+)>; rel="(\w+)"/
        const match = cur.match(pattern)
        if (match) return { ...acc, [match[2]]: match[1] }
        return { ...acc }
    }, {})
}

const extractTotalCount = (countHeader?: string) => {
    if (countHeader) return parseInt(countHeader)
}

const getNextQuery = (linkHeader?: string) => {
    let nextQueryKey
    const { next } = extractLinks(linkHeader)
    if (next) {
        const urlParams = new URLSearchParams(new URL(next).search)
        nextQueryKey = Object.fromEntries(urlParams)
    }
    return nextQueryKey
}

export const useApiInfiniteQuery = (params: {
    path: string
    query?: Query,
    onError?: () => void,
    options: {
        staleTime?: number,
        refetchOnWindowFocus?: boolean
    }
}) => {

    const { path, onError, options = {}, query = {} } = params || {}

    const queryClient = useQueryClient()
    const fetch = useRequestBuilder()

    const [currentPageIndex, setCurrentPageIndex] = useState(0)

    const queryFn = async ({ pageParam = query }) => {
        const fetchParams: RequestParams = {
            path,
            query: pageParam as Query
        }
        const { body, headers: { link, count } } = await fetch<Panel[]>(fetchParams)
        const nextQueryKey = getNextQuery(link)
        const total = extractTotalCount(count)
        return { content: body, nextQueryKey, total }
    }

    const {
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        data,
        refetch
    } = useInfiniteQuery({
        queryKey: [path, query],
        queryFn,
        getNextPageParam: (lastPage) => lastPage.nextQueryKey,
        onError,
        ...options
    })

    const pages = useMemo<Panel[][]>(() => {
        return _.map((data?.pages || []), 'content')
    }, [data])

    const all = useMemo<Panel[]>(() => {
        return _.map((data?.pages || []), 'content').flat()
    }, [data])

    const totalCount = useMemo<number | undefined>(() => {
        const fromHeader = _.last(data?.pages)?.total
        if (typeof fromHeader === 'number') {
            return fromHeader
        } else if (!hasNextPage) {
            //no more data, assume total count is the size of loaded results
            return _.map((data?.pages || []), 'content').flat().length
        }
    }, [data, hasNextPage])

    useEffect(() => {
        setCurrentPageIndex(0)
    }, [query, setCurrentPageIndex])

    const current = useMemo<Panel[]>(() => {
        //keep previous data until current page loaded
        return pages[currentPageIndex] || _.last(pages) || []
    }, [pages, currentPageIndex])

    const prev = useCallback(() => {
        setCurrentPageIndex(currentPageIndex - 1)
    }, [currentPageIndex, setCurrentPageIndex])

    const next = useCallback(async () => {
        if (!isFetchingNextPage) {
            fetchNextPage({ cancelRefetch: true })
            setCurrentPageIndex(currentPageIndex + 1)
        }
    }, [isFetchingNextPage, currentPageIndex, setCurrentPageIndex, fetchNextPage])

    const currentPageIsAnIntermediatePage = (!!pages.length && currentPageIndex < pages.length - 1)

    const refetchWithBackOnFirstPage = useCallback(() => {
        if (data) {
            queryClient.setQueryData([path, query], {
                pages: data.pages.slice(0, 1),
                pageParams: data.pageParams.slice(0, 1)
            })
        }
        setCurrentPageIndex(0)
        refetch({ cancelRefetch: true })
    }, [path, query, data, queryClient, setCurrentPageIndex, refetch])

    return {
        pages,
        current,
        currentPageIndex,
        all,
        totalCount,
        isFetching,
        isFetchingNextPage,
        hasNext: hasNextPage || currentPageIsAnIntermediatePage,
        next,
        hasPrev: !!currentPageIndex,
        prev,
        refetch: refetchWithBackOnFirstPage
    }
}

const useNotify = () => {
    const dispatch = useDispatch()
    return useCallback((...params: Parameters<typeof notify>) => {
        dispatch(notify(...params))
    }, [dispatch])
}

export const usePanels = (args: usePanelsArgs = {}) => {
    const { limit = false, staleTimeQuery = 30 * 1000, projection } = args

    const notify = useNotify()

    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_DELAY)

    const [filters, setFilters] = useState<Filters | null>(null)
    const removeFilters = useCallback(() => setFilters(null), [setFilters])

    const [pageLimit, setPageLimit] = useState<number>(LIST_PANELS_DEFAULT_LIMIT)

    const paginatedQuery = limit || projection

    const query = useMemo<Query>(() => {
        const searchParams = { search: debouncedSearch.length >= SEARCH_MIN_LENGTH ? debouncedSearch : '' }
        const paginationParams = limit || projection ? { limit: pageLimit } : {}
        const filterParams = searchParams.search ? {} : filters
        return ({
            projection,
            ...searchParams,
            ...filterParams,
            ...paginationParams
        })
    }, [limit, projection, debouncedSearch, filters, pageLimit])

    const {
        current,
        currentPageIndex,
        all,
        totalCount,
        hasNext,
        next,
        hasPrev,
        prev,
        isFetching,
        refetch
    } = useApiInfiniteQuery({
        path: PANELS_PATH,
        query,
        onError: () => { notify('error', 'panelLoadError', 3000) },
        options: {
            staleTime: staleTimeQuery,
            refetchOnWindowFocus: false
        }
    })

    return {
        panels: current,
        pagination: {
            totalCount,
            currentPageIndex,
            hasNext,
            next,
            hasPrev,
            prev,
            ...(current.length ? {
                firstInPage: (paginatedQuery ? currentPageIndex * pageLimit : 0) + 1,
                lastInPage: paginatedQuery ? currentPageIndex * pageLimit + current.length : all.length
            } : {})
        },
        loading: isFetching,
        refetch,
        setSearch: (search: string) => {
            removeFilters()
            setSearch(search)
        },
        search,
        setPageLimit,
        pageLimit,
        setFilters: (filters: Filters) => {
            setSearch('')
            setFilters(filters)
        },
        removeFilters,
        filters: filters
    }
}

export const usePanel = () => {

    const notify = useNotify()

    const queryClient = useQueryClient()

    const invalidateQueries = useCallback(() => {
        queryClient.invalidateQueries(PANELS_PATH)
    }, [queryClient])

    const builder = useRequestBuilder()

    const createPanelFn = useCallback(
        async (panel: CreatePanel) => {
            return builder<Panel>({
                method: 'POST',
                path: `${PANELS_PATH}`,
                body: panel
            })
        }, [builder])

    const createPanelMutation = useMutation(
        'create-panel',
        createPanelFn,
        {
            onSuccess: () => {
                invalidateQueries()
                notify('info', 'panelAddSuccess', 3000)
            },
            onError: (error: Error) => {
                const msg = error?.name === '409' ? 'panelAddAlreadyRegisteredError' : 'panelAddError'
                let timeMs = 3000
                if (error?.name === '409')
                    timeMs *= 2
                notify('error', msg, timeMs)
            }
        }
    )

    const createPanel = useCallback(
        async (panel: CreatePanel) => {
            try {
                await createPanelMutation.mutateAsync(panel)
            } catch {
            }
        }, [createPanelMutation]
    )

    const updatePanelFn = useCallback(
        async (panel: EditPanel) => {
            const { name, description, location } = panel
            const body = {
                name,
                description,
                location
            }
            return builder<Panel>({
                method: 'PUT',
                path: `${PANELS_PATH}/${panel.mac}`,
                body
            })
        }, [builder])

    const updatePanelMutation = useMutation(
        'update-panel',
        updatePanelFn,
        {
            onSuccess: () => {
                invalidateQueries()
                notify('info', 'panelUpdateSuccess', 3000)
            },
            onError: () => {
                notify('error', 'panelUpdateError', 3000)
            }
        }
    )

    const updatePanel = useCallback(
        async (panel: EditPanel) => {
            try {
                await updatePanelMutation.mutateAsync(panel)
            } catch {
            }
        }, [updatePanelMutation]
    )

    const deletePanelFn = useCallback(
        async (mac: string) => {
            return builder({
                method: 'DELETE',
                path: `${PANELS_PATH}/${mac}`,
            })
        }, [builder])

    const deletePanelMutation = useMutation(
        'delete-panel',
        deletePanelFn,
        {
            onSuccess: () => {
                invalidateQueries()
                notify('info', 'panelDeleteSuccess', 3000)
            },
            onError: () => {
                notify('error', 'panelDeleteError', 3000)
            }
        }
    )

    const deletePanel = useCallback(
        async (mac: string) => {
            try {
                await deletePanelMutation.mutateAsync(mac)
            } catch {
            }
        }, [deletePanelMutation])

    return {
        loading: createPanelMutation.isLoading || updatePanelMutation.isLoading || deletePanelMutation.isLoading,
        createPanel,
        updatePanel,
        deletePanel
    }
}