import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAdminPanels } from '../../hooks/adminPanels'
import { Card, SectionHeadings } from '../../common'
import { useAdminPanelForm } from './useAdminPanelForm'
import { AdminPanelForm } from './AdminPanelForm'
import { AdminPanelResults } from './AdminPanelResults'
import { notify } from '../../notification/actions'

export function AdminPanels() {
    const { serialNumber, onSubmit, onChange } = useAdminPanelForm()
    const { panels, loading, error } = useAdminPanels({ serialNumber })
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        if (error) {
            dispatch(notify('error', error.toString()))
        }
    }, [error, dispatch])

    return (
        <>
            <h2 className="title-page">{t('adminPanels')}</h2>
            <div className="page-container">
                <SectionHeadings title={t('adminPanelsSearchTitle')} containerClass='border-none' />
                <Card pending={loading}>
                    <Card.Header>
                        <AdminPanelForm
                            serialNumber={serialNumber}
                            placeholder={t('adminPanelsSearchBySerialPlaceholder')}
                            onSubmit={onSubmit}
                            onChange={onChange} />
                    </Card.Header>
                    <Card.Body full>
                        <AdminPanelResults
                            title={t('adminPanelsNoitemTitle')}
                            serialNumber={serialNumber}
                            panels={panels}
                            loading={loading}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}