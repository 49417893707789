import React, { createContext, useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { Button, Card } from '../../common'
import CompaniesSearchFilters from './CompaniesSearchFilters'
import CompaniesSearchInput from './CompaniesSearchInput'

type CompaniesSearchFormData = {
    businessName: string
    email: string
    country: string
    province: string
    status: string
}

interface CompanySearchFormProps {
    loading: boolean
    onSubmit: (data: CompaniesSearchFormData) => void
}

interface CompanySearchFormContext {
    data: CompaniesSearchFormData,
    setData: React.Dispatch<React.SetStateAction<CompaniesSearchFormData>>
}

const initialState = {
    businessName: '',
    email: '',
    country: '',
    province: '',
    status: ''
}

export const CompaniesSearchFormContext = createContext<CompanySearchFormContext | null>(null)

export function CompaniesSearchForm(props: CompanySearchFormProps) {
    const [data, setData] = useState<CompaniesSearchFormData>(initialState)
    const { onSubmit, loading } = props
    const { t } = useTranslation()

    const submitHandler = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onSubmit(data)
    }

    return (
        <CompaniesSearchFormContext.Provider value={{data, setData }}>
            <form onSubmit={submitHandler}>
                <Card pending={loading}>
                    <Card.Body otherClasses={styles.cardBody}>
                        <CompaniesSearchInput />
                        <CompaniesSearchFilters />
                    </Card.Body>
                    <Card.Footer otherClasses={styles.cardFooter}>
                        <Button
                            data-testid="companies-search-submit"
                            color="primary"
                            otherButtonClasses={styles.submit}
                            text={t('search')}
                            icon={<SearchIcon className={styles.submitIcon} />}
                            type="submit"
                        />
                    </Card.Footer>
                </Card>
            </form>
        </CompaniesSearchFormContext.Provider>
    )
}

const styles = {
    cardBody: 'grid grid-cols-1 gap-y-2',
    cardFooter: 'text-right',
    submit: 'capitalize',
    submitIcon: 'h-5 w-5 text-gray-400',
}