import React, {FC } from 'react'

const FullBodyLayout: FC = (props) => {
    const { children } = props
    const logo = require('../images/logo-secure-web.svg').default
    return (
        <div className="h-screen flex flex-col items-center justify-between bg-gray-50 p-4">
            <header className="w-full">
                <img src={logo} alt="SecureWeb logo" className='h-20' />
            </header>
            <main role="main" className='w-full'>
                {children}
            </main>
            <footer />
        </div>
    )
}

export default FullBodyLayout