import { useState } from 'react'
import { useQuery } from 'react-query'
import { PANELS_PATH } from '../utils'
import { Panel } from '../../panels/types'
import joi from '@hapi/joi'
import { RequestParams, useRequest } from '../request'

export const serialNumberLength = 16

const fetchOptions = {
    refetchOnWindowFocus: false
}

const serialNumberSchema = joi.string().length(serialNumberLength).alphanum()

export const useAdminPanels = (params: { serialNumber?: string } = {}) => {
    const [panels, setPanels] = useState<Panel[]>([])
    const { serialNumber = '' } = params

    const requestParams: RequestParams = {
        path: PANELS_PATH,
        query: {
            serialNumber: serialNumber.toUpperCase(),
            projection: 'full',
            searchDomain: 'full',
            limit: 1
        }
    }

    const { request: searchPanels } = useRequest<Panel[]>(requestParams)

    const { refetch, isFetching, error } = useQuery(
        ['admin-list-panels', requestParams],
        searchPanels,
        {
            enabled: !serialNumberSchema.validate(serialNumber).error,
            onSuccess: (data) => {
                if (data && data?.body) setPanels(data.body)
            },
            ...fetchOptions
        }
    )

    return {
        error,
        panels,
        loading: isFetching,
        refetch
    } as {
        error: ErrorConstructor
        panels: typeof panels
        loading: typeof isFetching
        refetch: typeof refetch
    }
}
