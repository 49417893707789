import React, { useState } from 'react'
import { CheckIcon } from '@heroicons/react/outline'

interface CompaniesSearchFilterToggleProps {
    label: string
    children: React.ReactNode,
    onOpen?: () => void
    onClose?: () => void
}

const CompaniesSearchFilterToggle = (props: CompaniesSearchFilterToggleProps) => {
    const { label, children, onOpen, onClose } = props
    const [open, setOpen] = useState(false)

    const clickHandler = () => {
        if (open) {
            setOpen(false)
            onClose && onClose()
        } else {
            setOpen(true)
            onOpen && onOpen()
        }
    }

    return (
        <div className={styles.container}>
            <button type="button" className={styles.button} onClick={clickHandler} data-testid="filter-button">
                <span>{label}</span>
                <span className={styles.toggle}>
                    {open ? <CheckIcon className={styles.toggleIcon} /> : null}
                </span>
            </button>
            {open && (
                <div className={styles.panel}>
                    {children}
                </div>
            )}
        </div>
    )
}

const styles = {
    container: 'bg-gray-50 rounded-md group px-4 py-1',
    button: 'flex w-full justify-between py-1 text-left text-sm text-gray7900 focus:outline-none',
    panel: 'text-sm text-gray-500 pb-3',
    iconPlus: 'h-5 w-5 text-gray-300 group-hover:text-gray-500',
    toggleIcon: 'h-4 w-4 text-brand-500',
    toggle: 'flex items-center justify-center w-5 h-5 rounded-sm bg-white border-gray-200 border'
}

export default CompaniesSearchFilterToggle