import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Button } from '../common/Button'
import { useLoading } from '../hooks/loading'
import Pin from '../common/Pin'
import { useDispatch } from 'react-redux'
import { notify } from '../notification/actions'
import { validatePassword } from '../common/Input/InputPasswordValidator'
import { NewPassword } from '../common/NewPassword'
import { useFormInput } from '../hooks/HandleChange'
import { useAuth } from '../auth/amplify'

export function ForgotPasswordForm(props: {
    onResetComplete: () => void
    username: string
}) {
    const { t } = useTranslation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const { forgotPassword, forgotPasswordSubmit, setUsername } = useAuth()

    useEffect(() => {
        setUsername(props.username)
    }, [setUsername, props.username])

    const [filledCode, setFilledCode] = useState('')
    const { reset: _, ...newPassword } = useFormInput()


    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await execute(async () => forgotPasswordSubmit(
                filledCode, newPassword.value
            ))
            props.onResetComplete()
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }
    }

    const onResend = async () => {
        try {
            await forgotPassword()
        } catch (e: any) {
            dispatch(notify('error', e.code))
        }
    }

    return <div className="max-w-md w-full">
        <form className="mt-8" autoComplete="new-password" action="#" method="POST" onSubmit={onSubmit}>
            <p className="text-sm text-center">
                {t('insertRegistrationCode')}
            </p>
            <p className="text-sm text-center mb-4">
                {t('notReceived')}&nbsp;
                <Button type="button" link text={t('resend')} otherTextClasses="text-brand-500" onClick={onResend} />
            </p>
            <Pin
                digits={6}
                onFilled={setFilledCode}
            />
            <p className={'text-sm text-center mt-4'}>{t('resetPasswordNewPassword')}</p>
            <NewPassword newPassword={newPassword} />
            <Button
                disabled={!validatePassword(newPassword.value)}
                otherButtonClasses="mt-8"
                pending={loading}
                text={t('confirm')}
                color="primary"
                full
            />
        </form>
    </div>
}

