import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { EmojiSadIcon } from '@heroicons/react/outline'
import { useAdminCompaniesSearch } from '../../hooks/adminCompanySearch'
import { notify } from '../../notification/actions'
import { Divider, MoreResults, NoItem, SectionHeadings } from '../../common/'
import CompaniesItem from './CompaniesItem'
import { CompaniesSearchForm } from './CompaniesSearchForm'

const RESULTS_LIMIT = 10

export function CompaniesSearch() {
    const limit = RESULTS_LIMIT
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { companies, setSearchValues, error, loading, resetSearch, refetch, enabled } = useAdminCompaniesSearch({ limit })

    const reset = useCallback(() => {
        resetSearch()
    }, [resetSearch])

    useEffect(() => {
        if (error) {
            dispatch(notify('error', error.toString()))
            reset()
        }
    }, [error, dispatch, reset])

    const resultsMaybeAreLimited = companies.length === RESULTS_LIMIT
    const noCompaniesToDisplay = enabled && companies.length === 0
    return (
        <>
            <SectionHeadings title={t('adminCompanySearchTitle')} containerClass='border-none' />
            <CompaniesSearchForm onSubmit={setSearchValues} loading={loading} />
            <NoItem show={noCompaniesToDisplay} additionalClasses='m-4 sm:m-6' icon={EmojiSadIcon} title={t('adminCompanySearchNoitemTitle')} />
            {companies.length > 0 && (
                <>
                    <Divider border={false} size='lg' />
                    <SectionHeadings title={`${t('searchResults')} (${companies.length})`} containerClass='border-none' />
                    <ul className="space-y-3">
                        {companies.map(company => (
                            <li key={company._id}>
                                <CompaniesItem
                                    data={company}
                                    actions={['edit']}
                                    onRefetch={refetch}
                                    tabsToShow={['company', 'address', 'billing', 'certification', 'members']}
                                />
                            </li>
                        ))}
                    </ul>
                    <MoreResults show={resultsMaybeAreLimited} limit={RESULTS_LIMIT} />
                </>)
            }
        </>
    )
}