import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserSearchType } from '../../hooks/adminUsersSearch'
import UserSearchCard from './UserSeachCard'

interface UserSearchResultsProps {
    users: UserSearchType[]
    refetch: () => void
}

const UserSearchResults = (props: UserSearchResultsProps) => {
    const { users, refetch } = props
    const { t } = useTranslation()

    if (users.length === 0) {
        return null
    }

    return (
        <>
            <div className="mt-6 text-sm text-gray-500 text-right">{users.length} {t('results')}</div>
            <ul className='grid grid-cols-[repeat(auto-fit,minmax(min(28rem,100%),1fr))] gap-6 mt-6'>
                {users.map(user => <UserSearchCard key={user._id} user={user} refetch={refetch} />)}
            </ul>
        </>
    )
}

export default UserSearchResults