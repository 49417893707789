import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { notify } from '../../notification/actions'
import { useAdminCompanies } from '../../hooks/adminCompanies'
import { NoItem } from '../../common/NoItem'
import CompaniesItem from './CompaniesItem'
import { OfficeBuildingIcon, RefreshIcon } from '@heroicons/react/outline'
import { Button } from '../../common/Button'
import SectionHeadings from '../../common/SectionHeadings'
import { Spinner } from '../../common/Spinner'
import { CompanyType } from '../../profile/selectors'

export function CompaniesPending() {
    const { companies, loading = false, refetch, error } = useAdminCompanies({ status: 'unverified', types: 'installer,distributor,freelance', limit: '20', sortBy: '-createdAt' })
    const [pending, setPending] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (error) {
            dispatch(notify('error', error.toString()))
        }
    }, [error, dispatch])

    const getTabsToShowByCompanyType = (type: CompanyType): string[] => {
        if (type === 'freelance')
            return ['company', 'address', 'members']
        return ['company', 'address', 'billing']
    }

    return (
        <>
            <SectionHeadings
                title={`${t('adminCompaniesPendingTitle')} (${companies.length})`}
                containerClass="border-none"
                aside={
                    <Button
                        size="xs"
                        type="button"
                        onClick={() => refetch()}
                        icon={<RefreshIcon className={'btn-icon'} />}
                    />
                }
            />
            <div>
                <NoItem
                    title={t('noPendingCompaniesTitle')}
                    show={!loading && !companies.length}
                    icon={OfficeBuildingIcon}
                    additionalClasses="m-4"
                />
                <Spinner show={pending || loading} />
                {companies.length > 0 && (
                    <ul className="space-y-3">
                        {companies.map((company) => (
                            <li key={company._id}>
                                <CompaniesItem
                                    key={company._id}
                                    data={company}
                                    onUpdating={() => setPending(true)}
                                    onUpdated={() => setPending(false)}
                                    onRefetch={() => refetch()}
                                    showStatus={false}
                                    tabsToShow={getTabsToShowByCompanyType(company.type)}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    )
}