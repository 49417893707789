import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import ReactSelect from 'react-select'

interface PanelsListFooterProps {
    firstInPage?: number
    lastInPage?: number
    count?: number
    pageLimit: number
    pageLimitOptions: number[]
    onPageLimitChange: (limit: number) => void
    prev?: boolean
    next?: boolean
    onPrev?: () => void
    onNext?: () => void
}

export function PanelsListFooter(props: PanelsListFooterProps) {
    const { prev, next, count, firstInPage, lastInPage, pageLimit, pageLimitOptions, onPageLimitChange, onPrev, onNext } = props
    const { t } = useTranslation()
    const styles = makeStyles()

    return (
        <div className="w-full mx-auto justify-between">
            <nav className={styles.pagination}>
                <div className={styles.indication}>
                    <span className="text-sm">{t('elementsPerPage')}</span>
                    <ReactSelect
                        data-testid="page-limit-select"
                        menuPlacement='auto'
                        isSearchable={false}
                        isMulti={false}
                        isClearable={false}
                        placeholder=''
                        value={pageLimitOptions.map(value => ({ value, label: value })).filter(o => o.value === pageLimit)}
                        styles={{
                            menu: (base) => ({
                                ...base,
                                minWidth: "50px"
                            })
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...(theme?.colors || {}),
                                primary: 'var(--color-brand-600)'
                            }
                        })}
                        options={pageLimitOptions.map(value => ({ value, label: value }))}
                        onChange={(e) => onPageLimitChange(e?.value as number)}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                    {firstInPage && lastInPage ? <div className="text-sm text-gray-700">
                        <span className="font-bold">{`${firstInPage}-${lastInPage} `}</span>
                        {typeof count === 'number' ? <>
                            <span>{`${t('of')} `}</span>
                            <span className="font-bold">{count}</span>
                        </> : null}
                    </div> : null}
                </div>
                <ul className={styles.pageNavigation}>
                    <li>
                        <button
                            onClick={onPrev}
                            disabled={!prev}
                            className={clsx(styles.button, 'rounded-l')}
                        >
                            {t('previous')}
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={onNext}
                            disabled={!next}
                            className={clsx(styles.button, 'rounded-r')}
                        >
                            {t('next')}
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

const makeStyles = () => {
    return {
        pagination: 'flex flex-col sm:flex-row items-center justify-between gap-2',
        indication: 'flex items-center justify-center gap-2',
        pageNavigation: 'inline-flex items-stretch -space-x-px',
        button: 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed'
    }
}