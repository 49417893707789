import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'
import { joinCompanyInvitations } from '../profile/selectors'
import FullBodyLayout from '../common/FullBodyLayout'
import JoinCompanyComponent from '../profile/JoinCompany'

export const JoinCompany = ({ children, path, ...rest }: PropsWithChildren<RouteProps>) => {
    const invitations = useSelector(joinCompanyInvitations)
    const userHasAPendingInvitation = () => invitations && invitations.length > 0
    return (
        <Route
            {...rest}
            path={path}
            render={() => {
                return userHasAPendingInvitation() ?
                    <FullBodyLayout>
                        <JoinCompanyComponent />
                    </FullBodyLayout>
                    : children
            }}
        />
    )
}