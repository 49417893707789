import _ from 'lodash'
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ApplicationRole, getApplicationRoles } from '../profile/selectors'

export const RedirectFromLogin = ({ children, path, ...rest }: PropsWithChildren<RouteProps>) => {
    const applicationRoles = useSelector(getApplicationRoles)
    const userIsLogged = (location: any) => !_.isEqual(applicationRoles, [ApplicationRole.NotLogged]) && location.pathname === '/login'

    const buildRedirectPath = (location: any) => {
        const fromPathName = location?.state?.from?.pathname
        return fromPathName === '/login' || !fromPathName ? { pathname: '/' } : location.state.from
    }

    return (
        <Route
            {...rest}
            path={path}
            render={({ location }: any) => {

                return userIsLogged(location) ? <Redirect to={buildRedirectPath(location)} /> : children
            }}
        />
    )
}