import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlusIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline'
import { Filters } from './types'
import { Input, Select } from '../common'

interface PanelsListHeaderProps {
    search: string,
    updateStateFilter: boolean,
    onSearch: (searchString: string) => void,
    onFilter: (filters: Filters) => void,
    onRemoveFilter: () => void,
    onReload: () => void,
    onAdd: () => void,
    filters?: Filters | null,
}

const updatedVersion = '1.119.0'
const veryOldVersion = '1.103.12'

const connectionFiltersValues = ['ETHERNET', 'MOBILE_4G', 'MOBILE_3G', 'MOBILE_2G', 'OFFLINE'] as const
type ConnectionFiltersValues = typeof connectionFiltersValues[number]
const versionFiltersValues = ['updated', 'old', 'veryOld'] as const
type VersionFiltersValues = typeof versionFiltersValues[number]
type FilterValues = ConnectionFiltersValues | VersionFiltersValues | 'all'

function mapFilters(filter: FilterValues) {
    if (['ETHERNET', 'MOBILE_4G', 'MOBILE_3G', 'MOBILE_2G'].includes(filter)) {
        return ({ connectionStatus: 'READY', connectionType: filter })
    }
    if (['updated'].includes(filter)) {
        return ({ versionMin : updatedVersion })
    }
    if (['old'].includes(filter)) {
        return ({ versionMax : updatedVersion, versionMin: veryOldVersion })
    }
    if (['veryOld'].includes(filter)) {
        return ({ versionMax : veryOldVersion })
    }
    return ({ connectionStatus: 'OFFLINE' })
}

function unmapFilters(filters: Filters) {
    const values = Object.values(filters)
    const isOffline = values.some(v => v === 'offline')
    return isOffline ? 'offline' : filters.connectionType
}

export function PanelsListHeader(props: PanelsListHeaderProps) {
    const { t } = useTranslation()
    const { search, filters, updateStateFilter } = props

    const filterValue = (!filters) ? 'all' : unmapFilters(filters)

    function changeFilterHandler(value: FilterValues) {
        value === 'all'
            ? props.onRemoveFilter()
            : props.onFilter(mapFilters(value))
    }

    return (
        <div className="flex pl-4">
            <div className="min-w-0 flex-1 flex items-center pr-2">
                <div className="relative min-w-0 flex-1 grid grid-cols-2 xl:grid-cols-3 gap-2 xl:gap-4 items-center py-2">
                    <Input
                        type="text"
                        name="search_panel"
                        id="search_panel"
                        placeholder={search ? undefined : t('typeSomethingToSearch')}
                        value={search}
                        onChange={ev => {
                            props.onSearch(ev.target.value)
                        }}
                        icon={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                    />
                    <Select
                        id="select_panel"
                        name="select_panel"
                        value={filterValue}
                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => changeFilterHandler(ev.target.value as FilterValues)}
                    >
                        <option value="all">{t('allPanels')}</option>
                        {updateStateFilter ?
                            <optgroup label={t('versionChartsTitle')}>
                                {versionFiltersValues.map(v => (
                                    <option key={v} value={v}>{t(`advancedFilter${v}`)}</option>
                                ))}
                            </optgroup>
                            : null}
                        <optgroup label={t('connectionChartsTitle')}>
                            {connectionFiltersValues.map(v => (
                                <option key={v} value={v}>{t(`advancedFilter${v}`)}</option>
                            ))}
                        </optgroup>
                    </Select>
                </div>
            </div>
            <div className="w-24 sm:w-32">
                <div className="flex h-full">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center w-1/2 p-1 shadow-sm focus:outline-none border-l border-gray-100 bg-white hover:bg-gray-50 text-gray-500"
                        onClick={() => props.onReload()}
                    >
                        <RefreshIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        data-testid={`addButton`}
                        type="button"
                        className="inline-flex items-center justify-center w-1/2 p-1 shadow-sm focus:outline-none border-l border-brand-600  bg-brand-600 hover:bg-brand-700 text-white rounded-tr-lg"
                        onClick={() => props.onAdd()}
                    >
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    )
}