import React from 'react'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import { useTranslation } from 'react-i18next'
import { Company } from '../../profile/selectors'


export function BillingTab(props: {
    company: Company
    companyIsADistributor: boolean
}) {
    const { t } = useTranslation()
    const { company, companyIsADistributor } = props
    const {
        vatNumber,
        country,
        pec,
        cuu,
        distributors,
        distributorsNote
    } = company
    const [distributor] = distributors

    return <dl className="sm:divide-y sm:divide-gray-200">
        <DescriptionList.Item label={t('registrationVatNumber')} value={vatNumber} />
        <DescriptionList.Item show={country === 'IT'} label={t('registrationPec')} value={pec} />
        <DescriptionList.Item show={country === 'IT'} label={t('registrationCuu')} value={cuu} />
        <DescriptionList.Item label={t('registrationDistributor')} value={distributor ?
            <>
                <span>{distributor?.businessName || '-'}</span>
                <span>{distributor?.address || '-'}</span>
            </> :
            <span>-</span>} />
        <DescriptionList.Item show={!companyIsADistributor && !!distributorsNote} label={t('distributorsNoteHelp')} value={distributorsNote} />
    </dl>
}