import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import Card from '../../common/Card/Card'
import SectionHeadings from '../../common/SectionHeadings'
import { Button } from '../../common/Button'
import useDownloadFile from '../../hooks/useDownloadFile'
import { notify } from '../../notification/actions'

export function UsersExport() {
    const { t } = useTranslation()
    const [pending, setPending] = useState(false)
    const dispatch = useDispatch()
    const { name, ref, url, download } = useDownloadFile({
        fileUrl: '/export/users-for-marketing',
        authRequired: true,
        accept: 'text/csv',
        preDownloading: () => { setPending(true) },
        postDownloading: () => { setPending(false) },
        onError: () => {
            setPending(false)
            dispatch(notify('error', 'errorExportingUsers'))
        },
        getFileName: () => `users-export-${new Date().toISOString()}.csv`
    })

    return (
        <>
            <SectionHeadings title={t('adminUserExportTitle')} containerClass='border-none' />
            <Card pending={pending}>
                <Card.Body>
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-col flex-1">
                            <h3 className="text-base">{t('adminUsersExportAllTitle')}</h3>
                        </div>
                        <div className="mt-3 flex space-x-2 sm:mt-0 sm:ml-4">
                            <a href={url} download={name} className="hidden" aria-hidden="true" ref={ref}>export</a>
                            <Button
                                text="Download CSV"
                                type="button"
                                onClick={download}
                                icon={<DocumentDownloadIcon className={'btn-icon'} />}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}