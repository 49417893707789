import React from 'react'
import { DownloadList } from './DownloadsList'

const favourites = ['lares_4.0', 'auxi', 'basis', 'porta_iot', 'domus', 'ergo']

export function ProductsAndSoftware() {
    return <DownloadList
        type='products'
        titleLabel='documentation-and-software'
        favourites={favourites}
        requireAuth={true}
    />
}

export default ProductsAndSoftware