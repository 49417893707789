import _ from 'lodash'
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ApplicationRole, CompanyRole, getCompanyRole, getApplicationRoles } from '../profile/selectors'

const redirectUser = (companyRole: CompanyRole, applicationRoles: ApplicationRole[]) => (location: any) => {
    if (location.pathname !== '/' || companyRole === CompanyRole.Unknown)
        return null
    if (_.intersection([ApplicationRole.Sales, ApplicationRole.Helpdesk], applicationRoles).length > 0)
        return <Redirect to={{ pathname: '/admin/companies', state: { from: location } }} />
    if (companyRole === CompanyRole.Visitor)
        return <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />
    if (_.isEqual(applicationRoles, [ApplicationRole.Customer]))
        return <Redirect to={{ pathname: '/panels', state: { from: location } }} />
}

export const RedirectFromRoot = ({ children, path, ...rest }: PropsWithChildren<RouteProps>) => {
    const companyRole = useSelector(getCompanyRole)
    const applicationRoles = useSelector(getApplicationRoles)
    return (
        <Route
            {...rest}
            render={({ location }: any) => redirectUser(companyRole, applicationRoles)(location) || children}
        />
    )
}