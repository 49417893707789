import React from 'react'
import { useTranslation } from 'react-i18next'
import { CompaniesSearch } from './CompaniesSearch'
import { CompaniesPending } from './CompaniesPending'
import { Divider } from '../../common/Divider'

export function Companies() {
    const { t } = useTranslation()
    return (
        <>
            <h2 className="title-page">{t('adminCompaniesTitle')}</h2>
            <div className="page-container">
                <CompaniesSearch/>
                <Divider border={false} size='lg' />
                <CompaniesPending />
            </div>
        </>
    )
}