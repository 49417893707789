import React from 'react'

export const LoyaltyProgramScores = (props: { scores: { [key: string]: { score: number } } }) => {
    const { scores } = props
    const years = Object
        .keys(scores)
        .filter(k => scores[k].score > 0)
        .sort((a, b) => a.localeCompare(b))
        .reverse()
        .slice(0, 2)
    const representation = years.map((year: string) => `${year}: ${props.scores[year].score}`).join(' - ')
    return <span>
        {representation}
    </span>
}
