import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { getApplicationRoles, ApplicationRole } from '../profile/selectors'

function handleRedirect(
    applicationRoles: ApplicationRole[],
    children: any
) {
    return ({ location }: any) => {
        if (_.isEqual(applicationRoles, [ApplicationRole.NotLogged])) {
            return children
        }
        return <Redirect to={{ pathname: '/', state: { from: location } }} />
    }
}

export const OnlyPublicRoute = ({ children, path, ...rest }: RouteProps) => {
    const applicationRoles = useSelector(getApplicationRoles)
    return (
        <Route {...rest} path={path} render={handleRedirect(applicationRoles, children)} />
    )
}
