import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { ChipIcon, LoginIcon, PencilIcon, QrcodeIcon, StatusOfflineIcon, StatusOnlineIcon, TagIcon } from '@heroicons/react/outline'
import { compareFwVersions, isALaresWls, mapPanelConnectionStatus, mapPanelConnectionType } from './utils'
import { Model, Panel } from './types'
import { paramsToObject } from '../common/utils'
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth'
import { getCurrentAuthConfig, useAuth } from '../auth/amplify'

const propagateAmplifySession = async (w: Window | null, targetOrigin: string, auth: AuthClass) => {
    try {
        const session: CognitoUserSession = await auth.currentSession()
        const IdToken = session.getIdToken().getJwtToken()
        const RefreshToken = session.getRefreshToken().getToken()
        const AccessToken = session.getAccessToken().getJwtToken()
        const Username = session.getAccessToken().payload.username
        const amplifySession = { IdToken, RefreshToken, AccessToken, Username }

        //pass the amplify config currentrly in use
        w?.postMessage(JSON.stringify({ type: 'aws-config', payload: { amplifyConfig: getCurrentAuthConfig(), amplifySession } }), targetOrigin)
    } catch (e) { }
}

const providePanelData = (w: Window | null, targetOrigin: string) => (data: object) => {
    try {
        w?.postMessage(JSON.stringify({ type: 'panel-data', payload: data }), targetOrigin)
    } catch (e) { }
}

const provideDataToInstaller = (w: Window | null, targetOrigin: string) => (propagateAuth: boolean, auth: AuthClass) => async (data: { query: { [key: string]: string }, voices?: string[] }) => {
    try {
        const installerReady = async (message: any) => {
            try {
                if (message.data === 'ready') {
                    window.removeEventListener('message', installerReady)

                    providePanelData(w, targetOrigin)(data)
                    if (propagateAuth && auth) {
                        await propagateAmplifySession(w, targetOrigin, auth)
                    }
                }
            } catch (e) { }
        }
        window.addEventListener("message", installerReady, false)
    } catch (e) { }
}

const openPanel = async (params: {
    panel: Pick<Panel, 'connectionAddress' | 'softwareVersion' | 'model'>
    propagateAuth: boolean
    voices: string[],
    auth: AuthClass
}) => {
    const { panel, propagateAuth, voices, auth } = params
    const url = panel.connectionAddress
    if (!url) {
        return
    }

    const isLaresWls = isALaresWls(panel.model as Model)
    const hasSupportForPanelConnectionDataViaPostMessage = compareFwVersions(panel.softwareVersion as string, process.env.REACT_APP_CONNECTION_DATA_VIA_POST_MESSAGE_MIN_INSTALLER_VERSION as string) >= 0

    const splittedUrl = url.split("?")
    const queryStringStripped = splittedUrl[0]
    const queryString = splittedUrl[1]
    let query = {}
    if (queryString) {
        const urlParams = new URLSearchParams(queryString)
        const entries = urlParams.entries()
        query = paramsToObject(entries)
    }

    let w

    if (isLaresWls) {
        //lares WLS
        const currentSession = await auth.currentSession()
        w = window.open(url + `&token=${currentSession.getIdToken().getJwtToken()}`, '_blank')
    } else if (hasSupportForPanelConnectionDataViaPostMessage) {
        //new lares 4.0 installer supporting connection data via post message
        //voices will be used only by BTicino installer
        w = window.open(queryStringStripped, '_blank')
        await provideDataToInstaller(w, queryStringStripped)(propagateAuth, auth)({ query, voices })
    } else {
        //old lares 4.0 installer
        w = window.open(url, '_blank')
        await provideDataToInstaller(w, url)(propagateAuth, auth)({ query })
    }
}

interface PanelsListItemProps {
    panel: Panel
    propagateAuth: boolean
    onEdit?: () => void
    onDisplayQrCode?: (name: string, serialNumber: string) => void
    voices?: string[]
}

export function PanelsListItem(props: PanelsListItemProps) {
    const { panel, voices = [], propagateAuth, onEdit, onDisplayQrCode } = props
    const { t } = useTranslation()
    const connectionStatus = mapPanelConnectionStatus(panel.connection)
    const connectionType = mapPanelConnectionType(panel.connection)
    const auth = useAuth()

    const hasEdit = onEdit && typeof onEdit === 'function'

    return (
        <div className="bg-transparent block">
            <div className="flex flex-col sm:flex-row">
                <div className="pt-2 px-4 sm:px-0 sm:pt-0 sm:w-28 flex sm:flex-col items-center sm:justify-center">
                    {connectionStatus === 'Online' ?
                        <>
                            <StatusOnlineIcon className="flex-shrink-0 h-5 w-5 text-green-400"
                                aria-hidden="true" />
                            <span className="text-gray-600 ml-1 sm:ml-0 sm:mt-1 text-xs">{t(`connectionType${connectionType}`)}</span>
                        </>
                        :
                        <>
                            <StatusOfflineIcon className="flex-shrink-0 h-5 w-5 text-gray-200"
                                aria-hidden="true" />
                            <span className="text-gray-600 ml-1 sm:ml-0 sm:mt-1 text-xs">{t('Notavailable')}</span>
                        </>
                    }
                </div>
                <div className="min-w-0 flex-1 flex flex-wrap sm:flex-nowrap sm:items-center py-2 px-4 sm:px-0 sm:pl-4">
                    <div className="min-w-full sm:min-w-0 sm:w-auto flex-1 mb-2 sm:mb-0 sm:pr-4">
                        <p className="text-sm font-medium text-brand-500">{panel.name}</p>
                        <p className="flex items-center text-xs text-gray-600 line-clamp-2 sm:line-clamp-1">
                            {panel.description && panel.description.length ? panel.description : '-'}
                        </p>
                    </div>
                    <div className="order-1 sm:order-none flex-1 sm:flex-initial sm:w-60">
                        <p className="flex items-center">
                            <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                aria-hidden="true" />
                            {onDisplayQrCode ?
                                <button
                                    data-testid="open-qrcode"
                                    type="button"
                                    className="bg-white rounded-md text-xs text-brand-600 "
                                    onClick={() => {
                                        if (onDisplayQrCode)
                                            onDisplayQrCode(panel.name, panel.serialNumber)
                                    }}
                                >
                                    {panel.serialNumber}
                                </button> : <span className="text-gray-600 text-xs">{panel.serialNumber}</span>
                            }
                        </p>
                        <p className="flex items-center">
                            <TagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
                                aria-hidden="true" />
                            <span className="text-gray-600 text-xs">{panel?.model?.replace('IP', '') || '-'}</span>
                        </p>
                    </div>
                    <div className="flex sm:flex-col flex-1 sm:flex-initial items-center sm:w-28">
                        <ChipIcon className="flex-shrink-0 h-5 w-5 text-brand-200"
                            aria-hidden="true" />
                        <span className="text-gray-600 text-xs ml-1 sm:ml-0 sm:mt-1">{panel.firmwareVersion || '-'}</span>
                    </div>
                </div>
                <div className="sm:w-32 flex border-t sm:border-t-0">
                    {hasEdit ? (
                        <button
                            data-testid='edit-panel'
                            type="button"
                            className="flex flex-1 items-center justify-center py-2 sm:py-0 border-r border-l border-gray-100 text-gray-500 bg-gray-50 hover:bg-gray-100"
                            onClick={() => {
                                onEdit()
                            }}
                        >
                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    ) : null}
                    <button
                        data-testid="connect-to-installer"
                        type="button"
                        className={clsx(['flex flex-1 items-center justify-center py-2 sm:py-0'], [!panel.connectionAddress || connectionStatus !== 'Online' ? ['opacity-50', 'cursor-not-allowed'] : []])}
                        disabled={!panel.connectionAddress || connectionStatus !== 'Online'}
                        onClick={async () => {
                            await openPanel({ panel, propagateAuth, voices, auth: auth?.authClass })
                            // await openPanel({
                            //     panel: {
                            //         ...panel,
                            //         connectionAddress: 'http://localhost:8080?env=api.sandbox.kseniasecureweb.com:8443&serialNumber=9E8E3C5BA3C20758&name=CarloBT'
                            //     },
                            //     propagateAuth,
                            //     voices
                            // })
                            document.body.click()
                        }}
                    >
                        <span className="text-gray-600">
                            <LoginIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}