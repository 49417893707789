import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../common/Card/Card'
import { UserSearchType } from '../../hooks/adminUsersSearch'
import DescriptionList from '../../common/DescriptionList/DescriptionList'
import UserCertification from './UserCertification'

interface UserSearchCardProps {
    user: UserSearchType
    refetch: () => void
}
const UserSearchCard = (props: UserSearchCardProps) => {
    const { user, refetch } = props
    const { t } = useTranslation()

    const companyName = user.companyId ? [`ID: ${user.companyId}`, user.businessName, `${t(user.type || '').toUpperCase()} - ${t(user.status || '').toUpperCase()}`].join('\r\n') : undefined

    const userName = user.surname ? `${user.name} ${user.surname}` : undefined

    return (
        <Card>
            <Card.Body full>
                <DescriptionList>
                    <DescriptionList.Item
                        label={t('registrationName')}
                        value={userName}
                        valueClass="text-brand-500"
                    />
                    <DescriptionList.Item
                        label="ID"
                        value={user._id}
                    />
                    <DescriptionList.Item
                        label={t('registrationEmail')}
                        value={user.email}
                    />
                    <DescriptionList.Item
                        label={t('registrationPhone')}
                        value={user.phoneNumber}
                    />
                    <DescriptionList.Item
                        label={t('marketingConsent')}
                        value={user.acceptedMarketing ? t('yes') : t('no')}
                        valueClass="capitalize"
                    />
                    <DescriptionList.Item
                        label={t('company')}
                        value={companyName}
                    />
                    <UserCertification
                        userId={user._id}
                        label={t('certificationLevel')}
                        value={user.certificationLevel}
                        onUpdate={refetch}
                    />
                </DescriptionList>
            </Card.Body>
        </Card>

    )
}

export default UserSearchCard