import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCompanySearchFormContext } from './useCompanySearchFormContext'
import CompaniesSearchStatusFilter from './CompaniesSearchStatusFilter'
import CompaniesSearchCountryFilter from './CompaniesSearchCountryFilter'
import CompaniesSearchFilterToggle from './CompaniesSearchFilterToggle'

type FilterTypes = 'country' | 'status'

const CompaniesSearchFilters = () => {
    const { t } = useTranslation()
    const { data, setData } = useCompanySearchFormContext()

    const closeHandler = (filter: FilterTypes) => () => {
        if (filter !== 'country') {
            setData({ ...data, [filter]: '' })
        } else {
            setData({ ...data, country: '', province: '' })
        }
    }

    return (
        <div className="grid grid-cols-1 gap-y-2">
            <CompaniesSearchFilterToggle
                key='country'
                label={t('companiesSearchFilterCountry')}
                onClose={closeHandler('country')}
            >
                <CompaniesSearchCountryFilter />
            </CompaniesSearchFilterToggle>
            <CompaniesSearchFilterToggle
                key='status'
                label={t('companiesSearchFilterStatus')}
                onClose={closeHandler('status')}
            >
                <CompaniesSearchStatusFilter />
            </CompaniesSearchFilterToggle>
        </div>
    )
}

export default CompaniesSearchFilters
