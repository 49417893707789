import React from 'react'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { CompanyStatus } from '../../profile/selectors'
import { useCompanySearchFormContext } from './useCompanySearchFormContext'


const CompaniesSearchStatusFilter = () => {
    const { t } = useTranslation()
    const { data, setData } = useCompanySearchFormContext()
    const changeStatusHandler = (status: CompanyStatus) => setData({ ...data, status })
    
    return (
        <RadioGroup value={data.status} onChange={changeStatusHandler} className="mt-2">
            <div className={styles.options} data-testid="status-filter">
                {['verified', 'unverified', 'invalid'].map((option) => (
                    <RadioGroup.Option
                        data-testid={`status-${option}`}
                        key={option}
                        value={option}
                        className={({ checked }) =>
                            clsx(styles.option, checked ? styles.optionChecked : styles.optionUnChecked)
                        }
                    >
                        <RadioGroup.Label as="span">{t(option)}</RadioGroup.Label>
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}

const styles = {
    options: 'grid grid-cols-3 gap-2',
    option: 'border rounded-md py-1 px-3 flex items-center justify-center text-sm uppercase cursor-pointer sm:flex-1',
    optionUnChecked: 'bg-white border-gray-100 text-gray-700',
    optionChecked: 'bg-brand-600 border-transparent text-white'
}

export default CompaniesSearchStatusFilter