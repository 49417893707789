import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { updateUser, UpdateUser } from '../createCompanyWizard'
import { useAuth } from '../../auth'

export type AdminUserMutation = {
    id: string,
    body: UpdateUser
}

export const useAdminUserMutation = () => {
    const authClass = useAuth()

    const updateFn = useCallback(async (body: AdminUserMutation) => {
        const token = await authClass.getIdToken()
        return updateUser(token)(body)
    }, [authClass])

    const updateMutation = useMutation(updateFn)
    const update = (body: AdminUserMutation) => updateMutation.mutateAsync(body)

    return { update }
}