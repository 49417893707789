import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { VatNumberOption } from './CreateCompanyWizard'
import { UseFormMethods } from 'react-hook-form'
import { Country, countries } from 'jsvat-next'
import { belongsToEurope } from '../../billing/utils'
import { Select, Input } from '../../common'
import { getVatCountry } from './utils'

const RadioButton = (props: {
    checked?: boolean,
    label: string,
    description?: string,
    'aria-label': string,
    onChange: () => void,
    children?: React.ReactNode

}) => <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
            <label className='flex gap-4 w-full items-center'>
                <input
                    type="radio"
                    aria-label={props['aria-label']}
                    checked={props.checked}
                    className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300"
                    onChange={props.onChange}
                />
                <span className="text-3xl">{props.label}</span>
            </label>
            {props.description ? <span className="text-gray-500">{props.description}</span> : null}
        </div>
        {props.children}
    </div>

const mapToCountryOptions = (country: Country): { label: string; value: string } => {
    const countryCode = getVatCountry(country.codes[0])
    return {
        label: `${countryCode} (${country.name})`,
        value: countryCode
    }
}

export default function VAT(props: {
    isValid: boolean,
    vatNumber: string,
    setVatNumber: (vatNumber: string) => void,
    setVatNumberCountry: (vatNumberCountry: string) => void,
    setVatNumberOption: (option: VatNumberOption) => void,
    vatNumberCountry: string,
    vatNumberOption: VatNumberOption,
    useFormProps: UseFormMethods
}
) {
    const inputRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { register } = props.useFormProps

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    const availableCountries = countries
        .filter(country => belongsToEurope(country.codes[0]))
        .map(country => mapToCountryOptions(country))

    const vatNumberIsDisabled = props.vatNumberOption === 'withoutvat'

    return (
        <div className="flex flex-col h-full">
            <div className="flex-1 flex flex-col justify-center px-8 border-b border-gray-100">
                <RadioButton
                    aria-label="radio-with-vatnumber"
                    label={t('registrationCountryWithVatNumber')}
                    description={t('registrationVatNumberHint')}
                    checked={props.vatNumberOption === 'withvat'}
                    onChange={() => {
                        props.setVatNumberOption('withvat')
                        props.setVatNumber('')
                    }}
                >
                    <div className="flex gap-4" data-testid="set-vatnumber">
                        <Select
                            data-testid="select-vatnumber-country"
                            required
                            containerClass="w-56"
                            id="vatnumberPrefix"
                            name="vatnumberPrefix"
                            disabled={vatNumberIsDisabled}
                            ref={register({ required: true })}
                            onChange={(event) => props.setVatNumberCountry(event.target.value)}
                        >
                            <option value="">{t('selectACountry')}</option>
                            {availableCountries.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                        </Select>
                        <Input
                            data-testid="vat-number"
                            ref={inputRef}
                            id="vatnumber"
                            containerClass="w-full"
                            invalid={!props.isValid}
                            value={props.vatNumber}
                            placeholder={t('registrationVatNumber')}
                            onChange={(event) => props.setVatNumber(event.target.value)}
                            type="text"
                            disabled={vatNumberIsDisabled}
                        >
                            {!props.isValid && !!props.vatNumber ?
                                <Input.Alert>{t('registrationVatNumberError')}</Input.Alert> : null
                            }
                        </Input>
                    </div>
                </RadioButton>
            </div>
            <div className="flex-1 flex flex-col justify-center px-8">
                <RadioButton
                    data-testid="radio-without-vatnumber"
                    aria-label="radio-without-vatnumber"
                    label={t('registrationCountryWithoutVatNumber')}
                    description={t('registrationWithoutVatNumberHint')}
                    checked={props.vatNumberOption === 'withoutvat'}
                    onChange={() => {
                        props.setVatNumberOption('withoutvat')
                        props.setVatNumber('')
                    }}
                />
            </div>
        </div>
    )
}

