import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'
import { hasFirstStepsWizardActive } from '../profile/FirstSteps/selectors'
import { CompanyRole, getCompanyRole } from '../profile/selectors'
import FullBodyLayout from '../common/FullBodyLayout'
import FirstSteps from '../profile/FirstSteps/FirstSteps'

export const CompleteProfile = ({ children, ...rest }: PropsWithChildren<RouteProps>) => {
    const companyRole = useSelector(getCompanyRole)
    const firstStepsWizardActive = useSelector(hasFirstStepsWizardActive)
    const pendingMarketingAcceptance = () => {
        const isCompanyRoleKnown = () => companyRole !== CompanyRole.Unknown
        return isCompanyRoleKnown() && firstStepsWizardActive
    }
    return (
        <Route
            {...rest}
            render={() => {
                return pendingMarketingAcceptance() ? <FullBodyLayout>
                    <FirstSteps />
                </FullBodyLayout> : children
            }}
        />
    )
}