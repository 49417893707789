import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { EmojiSadIcon } from '@heroicons/react/outline'
import { useAdminUsersSearch } from '../../hooks/adminUsersSearch'
import { notify } from '../../notification/actions'
import { NoItem } from '../../common/NoItem'
import SectionHeadings from '../../common/SectionHeadings'
import UserSearchForm, { UserSearchFormData } from './UserSearchForm'
import UserSearchResults from './UserSearchResults'
import MoreResults from '../../common/MoreResults'

const RESULTS_LIMIT = 10

export function UsersSearch() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { setParams, params, error, pending, users = [], refetch } = useAdminUsersSearch()

    const hasParams = Object.keys(params).length !== 0
    const maybeAreLimited = users.length === RESULTS_LIMIT

    useEffect(() => {
        if (error && error.name !== '404') {
            dispatch(notify('error', error.toString()))
        }
    }, [error, dispatch])

    const onSubmitHandler = (params: UserSearchFormData) => {
        setParams(params)
    }

    return (
        <>
            <SectionHeadings title={t('adminUserSearchTitle')} containerClass='border-none' />

            <UserSearchForm pending={pending} onSubmit={onSubmitHandler} />

            <UserSearchResults users={users} refetch={refetch} />

            <MoreResults show={maybeAreLimited} limit={RESULTS_LIMIT} />

            <NoItem
                additionalClasses='m-4 sm:m-6 bg-white'
                icon={EmojiSadIcon}
                title={t('adminUserSearchNoitemTitle')}
                show={!pending && hasParams && users.length === 0}
            />
        </>
    )
}