import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { notify } from '../../notification/actions'
import { Spinner } from '../../common/Spinner'
import { useAdminCompany } from '../../hooks/adminCompany'
import { getAllMembersFromCompany } from '../../profile/selectors'
import CompanyMembersList from '../../profile/CompanyMembersList'

interface CompanyMembersPropsType {
    id: string
}

export function CompanyMembersAdmin(props: CompanyMembersPropsType) {
    const { id } = props
    const { company, loading, error } = useAdminCompany({ id })
    const [pending] = useState(false)
    const dispatch = useDispatch()

    const members = getAllMembersFromCompany(company)

    useEffect(() => {
        if (error) {
            dispatch(notify('error', error.toString()))
        }
    }, [error, dispatch])

    return <>
        <Spinner show={pending || loading} />
        <CompanyMembersList
            members={members}
            companyId={company?.id || ''}
            showHeaders={false}
            canEdit={false}
        />
    </>
}