import React, { FC } from 'react'
import { getVersion } from './utils'

export const AuthLayout: FC<{ year: string }> = (props) => {
    const { children, year } = props
    return (
        <div className="cool-background flex flex-col justify-between items-center sm:px-6 lg:px-8 pt-8">
            {/* Body */}
            <div className="relative bg-white max-w-lg w-full py-8 px-4 sm:px-10 my-auto shadow sm:rounded-lg z-10">
                <img className="mx-auto h-32 w-32 mb-8" src={require('../images/logo-secure-web.svg').default} alt="Logo Secure Web" />
                {children}
            </div>
            {/* Footer */}
            <div className="flex items-center justify-between w-full px-4 py-2 mt-8 border-t border-opacity-10 text-gray-300 text-xs">
                <span className="flex items-center"><img className="h-6 mr-1" src={require('../images/logo-ksenia-compact-white.svg').default} alt="Logo Ksenia Security" /> &copy; {year} Ksenia Security spa</span>
                <span className="text-gray-500">{getVersion(process.env.REACT_APP_BUILD_VERSION)}</span>
            </div>
        </div>
    )
}

export default AuthLayout