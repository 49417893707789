import React from 'react'
import { DownloadList } from './DownloadsList'

export function PricesAndCatalogs() {
    return <DownloadList
        type='marketing'
        titleLabel='prices-and-catalogs'
    />
}

export default PricesAndCatalogs