import React, { VFC, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar } from '../../../common/Avatar'
import { UserCompanyProfile } from '../../../profile/selectors'
import { useAuth } from '../../../auth'
import { useDispatch } from 'react-redux'
import { logoutCompleted } from '../../../logout/actions'
import { useQueryClient } from 'react-query'

interface Props {
    user?: UserCompanyProfile
}

export const MenuProfile: VFC<Props> = (props) => {
    const { user } = props
    const { t } = useTranslation()
    const { signOut } = useAuth()
    const dispatch = useDispatch()
    const client = useQueryClient()

    const fullname = user && user.name && user.surname ? `${user.name} ${user.surname}` : ''

    const doLogout = useCallback(async () => {
        await signOut()
        client.clear()
        await client.invalidateQueries()
        dispatch(logoutCompleted())
    }, [signOut, dispatch, client])

    let avatarSrc = user?.company?.logoUrl
    return (
        <div className="flex-shrink-0 flex flex-col border-t border-gray-800 bg-gray-900 py-2">
            <div className="flex items-center px-4 pb-2">
                {user && (
                    <Avatar
                        size={8}
                        type="circular"
                        src={avatarSrc}
                        alt="User avatar"
                    />
                )}
                <div className="ml-3">
                    {user && (
                        <p className="text-sm font-medium text-brand-400">
                            <NavLink className="hover:text-brand-300" to="/profile">{fullname}</NavLink>
                        </p>
                    )}
                    <p className="text-xs font-medium text-gray-300 group-hover:text-white">
                        <NavLink className="hover:text-white" onClick={doLogout} to="/">{t('logout')}</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}