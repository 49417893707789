import { useCallback, useRef, useState } from "react"
import { generateError } from "./utils"
import { useAuth } from "../auth"

interface DownloadFileProps {
    readonly fileUrl: string
    readonly authRequired?: boolean
    readonly accept?: string
    readonly preDownloading: () => void
    readonly postDownloading: () => void
    readonly onError: () => void
    readonly getFileName: () => string
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>
    readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
    readonly name: string | undefined
    readonly url: string | undefined
}

export async function fetchFile(url: string, accept?: string, bearerToken?: string) {
    let headers = {}
    if (accept) {
        headers = { ...headers, accept }
    }
    if (bearerToken) {
        headers = { ...headers, authorization: `Bearer ${bearerToken}` }
    }
    const response = await fetch(url, { method: 'GET', headers })
    if (!response.ok) {
        generateError(response)
    }
    return response.blob()
}

const useDownloadFile = ({
    fileUrl,
    authRequired,
    accept,
    preDownloading,
    postDownloading,
    onError,
    getFileName,
}: DownloadFileProps): DownloadedFileInfo => {

    const [remoteFileUrl] = useState<string>(fileUrl)
    const ref = useRef<HTMLAnchorElement | null>(null)
    const [url, setFileUrl] = useState<string>()
    const [name, setFileName] = useState<string>()
    const { getIdToken } = useAuth()

    const fetchFileFn = useCallback(async (url: string) => {
        let token
        if (authRequired) {
            token = await getIdToken()
        }
        return fetchFile(remoteFileUrl, accept, token)
    }, [getIdToken, remoteFileUrl, authRequired, accept])

    const download = useCallback(async () => {
        try {
            preDownloading()
            const file = await fetchFileFn(remoteFileUrl)
            const url = URL.createObjectURL(file)
            setFileUrl(url)
            setFileName(getFileName())
            ref.current?.click()
            postDownloading()
            URL.revokeObjectURL(url)
        } catch (error) {
            onError()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFileFn])

    return { download, ref, url, name }
}

export default useDownloadFile