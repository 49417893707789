import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Location,
    Panel as PanelType,
    panelActivationType
} from './types'
import { mapPanelConnectionType } from './utils'
import { ModalMode } from '../types'
import { GiftIcon, QrcodeIcon } from '@heroicons/react/outline'
import { EditField } from '../common/EditField'
import clsx from 'clsx'
import Map, { locationToPosition, Position, positionToLocation, validLocation, validPosition } from './maps/Maps'
import { Button } from '../common/Button'
import { PanelDelete } from './PanelDelete'

type PanelDetailsActions = {
    onCancel: () => void,
    onSave: (panel: PanelType) => void,
    onDelete: () => void,
    onActivate?: () => void
}

type PanelOptions = {
    mode: ModalMode
    open: boolean
    canEdit?: boolean
    canDelete?: boolean
    canActivate?: boolean
    map?: boolean
}

export function PanelActivationCard(props: { canActivate?: boolean, activation?: panelActivationType, onActivate?: () => void }) {
    const { t } = useTranslation()
    return (

        <div
            className={clsx(["relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"], props.canActivate ? "pb-12" : null)}
        >
            <dt>
                <div className="absolute bg-brand-500 rounded-md p-3">
                    <GiftIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">{t('loyaltyProgramTitle')}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                {!!props.activation ?
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        <p className="text-2xl font-semibold text-gray-900">{props.activation.points}</p>
                        <span className="ml-2 text-sm font-medium text-gray-500">{t('earnedPoints')}</span>
                    </div>
                    :
                    <div className=" h-6" />
                }
                {props.canActivate ? <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                        <button
                            type="button"
                            className="text-sm font-medium text-brand-500"
                            onClick={() => {
                                if (props.onActivate)
                                    props.onActivate()
                            }}
                        >
                            {t('activationAction')}
                        </button>
                    </div>
                </div> : null}
            </dd>
        </div>

    )
}

const serialNumberLenght = 16
const minNameLenght = 1
const trimNewLines = (value: string) => (value || '').replace(/(\r\n|\n|\r)/gm, " ").trim()
const validSerialNumber = (value: string) => (value || '').trim().length === serialNumberLenght
const validName = (value: string): boolean => (value || '').trim().length >= minNameLenght

function PanelAddContent(props: Pick<PanelOptions, 'map'> & Pick<PanelDetailsActions, 'onSave' | 'onCancel'>) {
    const { map, onSave, onCancel } = props
    const { t } = useTranslation()
    const [serialNumber, setSerialNumber] = useState({ value: '', touched: false })
    const [name, setName] = useState({ value: '', touched: false })
    const [description, setDescription] = useState({ value: '', touched: false })
    const [position, setPosition] = useState<Position>({ lat: null, lng: null, accuracy: null })
    const enableSave = validSerialNumber(serialNumber.value) && validName(name.value)

    const handleSelectPosition = useCallback((position: Position) => setPosition(position), [])

    return <>
        <div
            data-testid='panel-add-content'
            className="flex-1 overflow-y-auto">
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 overflow-y-hidden">
                <dl className="px-4 mt-2 divide-y divide-gray-200">
                    <div className="pt-2 pb-6">
                        <label className="block text-sm font-medium text-gray-700">
                            {t('serialNumber')}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 py-1 flex items-center pointer-events-none">
                                <QrcodeIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                data-testid={`serialNumber`}
                                className="block h-8 w-full pl-8 sm:text-sm border border-gray-200 rounded-md uppercase"
                                placeholder="12AB34CD56EF7890"
                                maxLength={serialNumberLenght}
                                minLength={serialNumberLenght}
                                onChange={(e) => { setSerialNumber({ value: trimNewLines(e.target.value).toUpperCase(), touched: true }) }}
                            />
                        </div>
                        {serialNumber.touched && !validSerialNumber(serialNumber.value) ? <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('invalidSerialNumberWarning')}
                        </p> : null}
                    </div>
                    <div className="py-4">
                        <div className="pb-2 items-start flex-col">
                            <label className="block text-sm font-medium text-gray-700">
                                {t('name')}
                            </label>
                            <input
                                data-testid={`name`}
                                className="w-full h-8 pl-3 mt-2 sm:text-sm border border-gray-200 rounded-md resize-none overflow-hidden"
                                placeholder={t('namePlaceholder')}
                                maxLength={250}
                                onChange={(e) => { setName({ value: trimNewLines(e.target.value), touched: true }) }}
                            />
                            {name.touched && !validName(name.value) ? <p className="mt-2 text-sm text-red-600" id="email-error">
                                {t('invalidNameWarning')}
                            </p> : null}
                        </div>
                        <div className="pb-2 items-start flex-col">
                            <label className="block text-sm font-medium text-gray-700">
                                {t('description')}
                            </label>
                            <textarea
                                className="w-full mt-2 h-40 sm:text-sm border border-gray-200 rounded-md resize-none overflow-hidden"
                                rows={5}
                                maxLength={500}
                                onChange={(e) => { setDescription({ value: trimNewLines(e.target.value), touched: true }) }}
                            />
                        </div>
                    </div>
                    {map ? <div className="py-4">
                        <div className="pb-2 items-start flex-col">
                            <label className="block text-sm font-medium text-gray-700">
                                {t('location')}
                            </label>
                        </div>
                        <Map
                            containerClassname="w-full h-60"
                            onSelectPosition={handleSelectPosition}
                            mode='add'
                        />
                    </div> : null}
                </dl>
            </div >
        </div >
        {/* Action buttons */}
        <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
            <div className="space-x-3 flex justify-between">
                <button
                    type="button"
                    className="min-w-min inline-flex justify-center px-2.5 py-1.5 border border-gray-200 rounded-md shadow-sm text-sm font-medium text-gray-900 hover:bg-gray-100"
                    onClick={() => { onCancel() }}
                >
                    {t('cancel')}
                </button>
                <button
                    type="button"
                    className={clsx("min-w-min inline-flex justify-center px-2.5 py-1.5 border border-gray-200 bg-brand-600 rounded-md shadow-sm text-sm font-medium text-white ", enableSave ? "hover:bg-brand-700" : "opacity-50 cursor-not-allowed")}
                    onClick={enableSave ? () => {
                        onSave({
                            name: name.value,
                            description: description.value,
                            serialNumber: serialNumber.value,
                            location: validPosition(position) ? positionToLocation(position as Position) : undefined
                        })
                    } : undefined}
                >
                    {t('savePanel')}
                </button>
            </div>
        </div>
    </>
}

function PanelEditContent(props: PanelType & Pick<PanelOptions, 'canEdit' | 'canActivate' | 'canDelete' | 'map'> & PanelDetailsActions) {
    const { canEdit, canActivate, canDelete, map, onSave, onDelete, onActivate, onCancel, location, ...panel } = props
    const { t } = useTranslation()
    const [name, setName] = useState(panel.name)
    const [description, setDescription] = useState(panel.description)

    const connectionType = t(`connectionType${mapPanelConnectionType(panel.connection)}`)

    //to prevent map re-render
    const [currentMapsPositions, setCurrentMapsPosition] = useState<Position[]>([])
    useEffect(
        () => {
            if (location && validLocation(location)) {
                setCurrentMapsPosition([locationToPosition(location as Location)])
            }
        },
        [location]
    )

    const [editedLocation, setEditedLocation] = useState<Location | undefined>(location)
    const handleSelectPosition = useCallback((position: Position) => {
        if (validPosition(position)) {
            setEditedLocation(positionToLocation(position as Position))
        }
    }, [])

    const isNameValid = validName(name)
    const formIsValid = isNameValid

    return <>
        <div
            data-testid="panel-edit-content"
            className="flex-1 overflow-y-auto">
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0  overflow-y-hidden">
                <dl className="px-4 mt-2 divide-y divide-gray-200">
                    <EditField
                        label={t('name')}
                        value={name}
                        edit={canEdit}
                        invalid={!isNameValid}
                        invalidLabel={t('invalidNameWarning')}
                        onChange={(value) => { setName(value) }}
                    />
                    <EditField
                        label={t('description')}
                        value={description}
                        edit={canEdit}
                        onChange={(value) => {
                            setDescription(value)
                        }}
                        rows={5}
                    />
                    {<EditField label={t('model')} value={panel.model ? panel.model.replace('IP', '') : '-'} />}
                    {<EditField label={t('firmwareVersion')} value={panel.firmwareVersion || panel.build || '-'} />}
                    {<EditField label={t('connection')} value={connectionType} />}
                    {<EditField label={t('serialNumber')} value={panel.serialNumber} />}
                    {map ? <div className="py-4">
                        <div className="pb-2 items-start flex-col">
                            <p className="text-sm font-medium text-gray-500 truncate">{t('location')}</p>
                        </div>
                        <div className={clsx([!canEdit ? "opacity-50 pointer-events-none" : null])}>
                            <Map
                                containerClassname="w-full h-60"
                                markers={currentMapsPositions}
                                onSelectPosition={handleSelectPosition}
                                mode={canEdit ? 'edit' : 'show'}

                            />
                        </div>
                    </div> : null}
                </dl>
                <div className="px-4 py-4 mt-4">
                    {panel.activation || canActivate ? <PanelActivationCard
                        activation={panel.activation}
                        canActivate={canActivate}
                        onActivate={onActivate}
                    /> : null}
                </div>

                {canDelete ? <PanelDelete onDelete={onDelete} /> : null}
            </div>
        </div>
        {/* Action buttons */}
        {canEdit ? <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
            <div className="space-x-3 flex justify-between items-center">
                <Button
                    type="button"
                    size="sm"
                    onClick={() => { onCancel() }}
                    text={t('cancel')}
                />
                <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                        onSave({
                            ...panel,
                            name: trimNewLines(name),
                            description: trimNewLines(description),
                            location: editedLocation
                        })
                    }}
                    text={t('save')}
                    disabled={!formIsValid}
                />
            </div>
        </div> : null}
    </>
}

export function Panel(props: (PanelType | undefined) & PanelOptions & PanelDetailsActions) {
    const { open, mode, canEdit, canDelete, canActivate, map, onSave, onCancel, onDelete, onActivate, ...panel } = props
    return <>
        {mode === 'edit' && panel ? <PanelEditContent
            {...panel}
            canEdit={!!canEdit}
            canActivate={!!canActivate}
            canDelete={!!canDelete}
            onSave={onSave}
            onActivate={onActivate}
            onDelete={onDelete}
            onCancel={onCancel}
            map={map}
        /> : null}
        {mode === 'add' ? <PanelAddContent
            onSave={onSave}
            onCancel={onCancel}
            map={map}
        /> : null}
    </>
}
